<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="notification is-info" v-if="loading">
                <button type="button" class="delete"></button>
                A tabela de preços está sendo salva, este processo pode levar alguns minutos.
            </div>    
            <b-tabs position="is-right" type="is-boxed" v-model="activeItemTab">
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab = 0"> Usar tabela referência </span>
                    </template>
                    <div class="column">
                        <searchIdName class="column is-12" :id.sync="filtro.tabelaprecoid" table="tabelapreco" 
                            label="Tabela de Preços" />
                    </div>
                    <div class="column">
                        <b-field class="column is-12" label="Aplicar alteração percentual">
                            <b-input type="number" v-model="filtro.percentual" max="100" ></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field class="column is-12" label="Nome da nova tabela">
                            <b-input type="text" maxlength="100" v-model="filtro.nome"></b-input>
                        </b-field> 
                    </div>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab = 1"> Criar tabela vazia </span>
                    </template> 
                    <div class="column">
                        <b-field class="column is-12" label="Nome da nova tabela">
                            <b-input type="text" maxlength="100" v-model="filtro.nome"></b-input>
                        </b-field> 
                    </div> 
                    <div class="column">
                        <b-field class="column is-12" label="Selecione o tipo da tabela de preço" >
                            <b-select  v-model="filtro.tipo" expanded  >
                                <option value="0" checked > Moeda</option>
                                <option value="1" > AMB</option>
                                <option value="2" > CBHPM</option>   
                                <option value="3" > Consumivel</option>   
                                <option value="4" > Custo</option>   
                            </b-select>                                                                                 
                        </b-field>
                    </div> 
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab = 2"> Importar excel </span>
                    </template>  
                    <div class="column">
                        <novaTabelaPrecoExcel></novaTabelaPrecoExcel>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success" v-show="activeItemTab != 2"
                                icon-left="check-circle" 
                                native-type="button"
                                :loading="loading"
                                @click="enviar()">
                        Criar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapMutations, mapState, mapGetters } from 'vuex'    
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import novaTabelaPrecoExcel from '@/views/cadastro/novatabelaprecoexcel.vue'

    export default {
        components: {
            titlebar,            
            searchIdName,
            novaTabelaPrecoExcel
        },
        data() {
            return {
                activeItemTab: 0,
                filtro:{
                    tabelaprecoid: null,
                    percentual:null,
                    nome:null,
                    tipo:0
                },
                loading: false,
            }
        },
        created() {
           return null;
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Tabela de preços',
                    'Novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),            
        },
        methods: {                  
            enviar(){
                this.loading = true;

                if( (this.activeItemTab == 0 || this.activeItemTab == 1 )  
                    && !this.filtro.nome ){

                    this.$buefy.toast.open({
                        message: "Insira o nome da tabela de preço !",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;

                }

                if( (this.activeItemTab == 0 )  
                    && !this.filtro.tabelaprecoid ){

                    this.$buefy.toast.open({
                        message: "Insira a tabela referência !",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;

                }                

                
                const params = [
                    `nome=${this.filtro.nome}`,
                    `tipo=${this.filtro.tipo}`,                                                                
                    
                ]      

                    if(this.filtro.tabelaprecoid){
                        params.push(`tabelaReferenciaId=${this.filtro.tabelaprecoid}`);
                    }
                    
                    if(this.filtro.percentual){
                        params.push(`percentual=${this.filtro.percentual}`);
                    }                     

                this.$http.get('/api/manutencao/NovaTabelaPreco?'+ params.join('&'))
                    .then(res => res.data)
                    .then(data => {                        
                        if(data.id){
                            this.$router.push({ name: 'tabelapreco', params: { id:data.id } });
                        }else{

                            this.$buefy.toast.open({
                                message: "Não foi possível criar a nova tabela de preço !",
                                type: 'is-danger'
                            })

                        }                                                        
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    });   

                                            
            }
            
           
        }
    }
</script>
<template>
  <section>
    <article class="panel">
      <div class="panel-heading has-text-centered">
        {{ label }}
      </div>
      <div class="panel-block">
        <b-table :data="interno.items" class="table" striped hoverable>
          <template >
             <b-table-column v-slot="props" 
              v-if="interno.exclusao" 
            >
            <b-field>
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="delete"
                class="button-delete"
                @click="deleteItem(props.row)"
              ></b-button>
            </b-field>
             </b-table-column>
            <b-table-column v-slot="props" 
              v-for="coluna in interno.colunas"
              :key="coluna.valor"
              :field="coluna.valor"
              :label="coluna.label"
            >       
              <b-input
                :ref="'campo'+props.index"
                type="number"
                v-model="props.row[coluna.valor]"
                v-if="coluna.tipo == 'subtotal'"
                :readonly="true"
                disabled="true"
              ></b-input>                  
              <b-input
                :ref="'campo'+props.index"
                v-model="props.row[coluna.valor]"
                v-if="coluna.tipo == 'texto'"
                :readonly="coluna.readonly"
                @input="change(coluna,props.row, props.index)"
              ></b-input>
              <b-field v-if="(coluna.valor == 'valorTotal' || coluna.valor == 'preco') && props.row.consumivel__id > 0 && props.row.quantidade > 0">
                  <p class="control" >
                    <button class="button is-light" type="button">
                      R$
                    </button>
                  </p>
                  <b-input
                    :ref="'campo'+props.index"
                    type="number"
                    disabled
                    :step="coluna.passos"
                    v-model="props.row[coluna.valor]"
                    v-if="coluna.tipo == 'numero'"
                    :readonly="coluna.readonly"
                    @input="change(coluna,props.row, props.index)"
                  ></b-input>
              </b-field>
              <div v-else-if="coluna.valor != 'valorTotal' && coluna.valor != 'preco'">
                <b-input
                      :ref="'campo'+props.index"
                      type="number"
                      :step="coluna.passos"
                      v-model="props.row[coluna.valor]"
                      v-if="coluna.tipo == 'numero'"
                      :readonly="coluna.readonly"
                      @input="change(coluna,props.row, props.index)"
                ></b-input>
              </div>
              <b-checkbox
                :ref="'campo'+props.index"
                v-model="props.row[coluna.valor]"
                v-if="coluna.tipo == 'checkbox'"
                :readonly="coluna.readonly"
                @input="change(coluna,props.row, props.index)"
                expanded
              ></b-checkbox>
              <b-tooltip v-if="coluna.tipo === 'select'" :label="props.row[coluna.valor][coluna.campoLabel]" multilined>    
                <b-select
                  :ref="'campo'+props.index"
                  v-model="props.row[coluna.valor][coluna.campoId]"
                  expanded
                  v-if="coluna.tipo === 'select'"
                  @input="change(coluna,props.row, props.index)"
                  :readonly="coluna.readonly"
                  :disabled="(props.row['id'] > 0) ? true : false"
                >
                  <option
                    v-for="option in coluna.opcoes"
                    :value="option[coluna.campoId]"
                    :key="option[coluna.campoId]"
                  >
                    {{ option[coluna.campoLabel] }}
                  </option>
                </b-select>
              </b-tooltip>
              <b-field label="" v-if="coluna.tipo === 'autocomplete'">
                    <b-autocomplete :data="data"
                                    :ref="'autocomplete_'+coluna.valor"
                                    :field="coluna.campoAutocomplete"
                                    :loading="isFetching"
                                    @typing="getAsyncData($event,coluna)"
                                    @select="selectItem($event,coluna,props.row)">
                        <template slot="empty">
                          {{$t('CADASTROGENERICO.NENHUMEXAME')}}
                        </template>
                        <template slot-scope="props">
                            <div class="is-flex is-justified-between">
                                <span>{{ props.option[coluna.campoAutocomplete] }}</span>
                                <span class="tag">{{ props.option.id }}</span>
                            </div>
                            <slot v-if="hasDefaultSlot"
                                  :option="props.option"
                                  :index="props.index" />
                        </template>
                    </b-autocomplete>
                </b-field>              
            </b-table-column>
          </template>
          <template slot="footer" v-if="colunaTotal && interno.items.length">
              <th v-if="interno.exclusao"></th>

              <th v-for="coluna in interno.colunas" :key="coluna">
                  <div class="th-wrap" v-if="coluna.valor === colunaTotal">
                      <b-field :label="$t('CADASTROGENERICO.TOTAL')">
                        <input disabled="true" class="input" :value="getValorTotal(coluna.valor)" />
                      </b-field>
                  </div>
              </th>
          </template>

        </b-table>
        <div class="has-text-right">
          <p v-if="interno.isPedido">
            <b>Total: R$ {{ totalConsumivel }}</b> 
          </p>
        </div>
        <div v-if="interno.inclusao" class="buttons has-text-centered">
          <b-button
            type="is-success"
            native-type="button"
            icon-left="plus"
            class="center"
            @click="addItem()"
          >
            Incluir
          </b-button>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
.button-delete {
  max-height: 1.8rem;
  margin-top: 0.2rem;
}

.id-column {
  max-width: 60px;
}

.table {
  width: 100%;
  display: block;
  /* max-height: 30vh;
  overflow-y: auto;   */
}

.panel-block {
  display: block;
}

.center {
  margin: 0 auto;
}
</style>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    items: null,
    colunas: null, 
    label: null,
    campoFoco: null,
    exclusao: null,
    inclusao: null,
    isPedido: null,
    total: null,
    colunaTotal: null,
    estoqueId: null,
  },
  data() {
    return {
      data: [],
      inputid: this.id,
      isFetching: false,
      interno: {
        items: [],
        colunas: [],
        campoFoco: "nome",
      },
      totalConsumivel: 0,
    };
  },
  mounted() {
    if (this.colunas) {
      this.interno.colunas = this.configurarColunas();
    }

    this.interno.campoFoco = this.campoFoco;

    if (this.inclusao != null){
      this.interno.inclusao = this.inclusao;
    }else{
      this.interno.inclusao = true;
    }

    if (this.exclusao != null){
      this.interno.exclusao = this.exclusao;
    }else{
      this.interno.exclusao = true;
    } 

    if (this.isPedido != null){
      this.interno.isPedido = this.isPedido;
    }   
    
    if (this.items?.length){
      const items = this.recarregarValoresDinamicosDasColunas(this.items,this.interno.colunas);      
      this.interno.items = this.items;
    }
  },
  watch: {
    items() {
      //Devo obter o valor para os ids caso sejam necessários
      //para cada coluna dinamica, devo recarregar os itens
      const items = this.recarregarValoresDinamicosDasColunas(this.items,this.interno.colunas);
      this.interno.items = items;   
    },
    colunas(novoValor) {
      this.interno.colunas = this.configurarColunas();
    },
    campoFoco(novoValor) {
      this.interno.campoFoco = novoValor;
    },
    exclusao(novoValor){
      if (novoValor != null){
        this.interno.exclusao = novoValor;
      }
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
  methods: {
    addItem() {
      const novoItem = { show: true };
      this.interno.colunas?.forEach((c) => {
        if (c.nomeCampoPai) {
          novoItem[c.nomeCampoPai] = {};
          novoItem[c.valor] = null;
        }
        if (c.tipo === "texto") novoItem[c.valor] = null;
        if (c.tipo === "select")         
          novoItem[c.valor] = {
            [c.campoId]:
              c.opcoes && c.opcoes[0] && c.opcoes[0][c.campoId]
                ? c.opcoes[0][c.campoId]
                : 0,
            [c.campoLabel]: c.opcoes && c.opcoes[0] && c.opcoes[0][c.campoLabel]
                ? c.opcoes[0][c.campoLabel]
                : null
          };
        
        if (c.tipo === "numero") novoItem[c.valor] = null;        
        if (c.tipo === "checkbox") novoItem[c.valor] = false;

        if (c.valorPadrao != null){
          novoItem[c.valor] = c.valorPadrao;
        }
      });

      this.interno.items.push(novoItem);
      const refs = this.$refs;
      const last = this.interno.items.length - 1;

      setTimeout(() => {        
        let numeroCampo = refs[`campo${last}`][0];
        if (numeroCampo?.$attrs?.readonly){
          numeroCampo = refs[`campo${last}`][1];
        }
        numeroCampo.focus();
      }, 100);
    },
    deleteItem(item) {
      if (this.interno.exclusao){
        if(item.id){
          this.interno.items = this.interno.items.filter((i) => i.id !== item.id);
        }
        else {
          this.interno.items = this.interno.items.filter((i) => 
            i[`${this.interno.colunas[0].nomeCampoPai}__${this.interno.colunas[0].campoId}`] !== item[`${this.interno.colunas[0].nomeCampoPai}__${this.interno.colunas[0].campoId}`]
          );
        }
        
        this.change();
      }
    },
    change(coluna,row, index) {              
      if (coluna && row){
        if (coluna.carregarCampo){
          this.getId(coluna,row, index);
        }
      }

      this.calcularSubtotal(row);

      if(this.interno.isPedido && row != null && row.preco != null) {
        this.somaConsumivel(row);
      }

      const result = this.interno.items;
      this.$emit("update:items", result);
    },
    somaConsumivel(consumivel){
      let total = 0;
      consumivel.valorTotal = parseFloat((consumivel.preco * consumivel.quantidade).toFixed(2))

      if(this.items)
        this.items.forEach(x => total += x.valorTotal)

      total >= 0 
        ? this.totalConsumivel = total.toFixed(2)
        : this.totalConsumivel = 0; 


      this.$forceUpdate();
    },
    configurarColunas(items) {
      let colunas = this.colunas;
      if (items) {
        colunas = items;
      }

      const colunasFiltradas = [];
      colunas.forEach((c) => {
        const coluna = this.getCampoPadrao(c);
        if (coluna.dinamica){
          if (coluna.colunas){
            coluna.colunas?.forEach(col => {
              col = this.getCampoPadrao(col);            
              col.dinamica = true;
              col.campoAutocomplete = col.campoAutocomplete ? col.campoAutocomplete : 'nome';
              col.tabela = coluna.tabela ? coluna.tabela : 'TABELA_NAO_INFORMADA';
              col.nomeCampoPai = coluna.valor;
              col.valor = `${col.nomeCampoPai}__${col.valor}`;

              colunasFiltradas.push(col);  
            })
          }else{
            colunasFiltradas.push(coluna);
          }
        }else{
          colunasFiltradas.push(coluna);
        }

      });

      return colunasFiltradas;
    },
    getCampoPadrao(campo) {
      const coluna = {
        ...(campo ? campo : {}),
        tipo: campo.tipo ? campo.tipo : "texto",
        readonly: campo.readonly != null ? campo.readonly : false,
        opcoes: campo.opcoes ? campo.opcoes : [],
        campoId: campo.campoId ? campo.campoId : "id",
        campoLabel: campo.campoLabel ? campo.campoLabel : "nome",
        passos: campo.passos ? campo.passos : 0
      };

      if (campo.tipo === 'autocomplete' && campo.dinamica){
        coluna.campoAutocomplete = coluna.campoAutocomplete ? coluna.campoAutocomplete : 'nome';


      }

      return coluna;
    },
    selectItem(option, coluna,row) { 
      if (option){        
        const campoId = coluna.carregarCampoId ? coluna.carregarCampoId : 'id';

        if (coluna.nomeCampoPai){   
                 
          row[`${coluna.nomeCampoPai}__${campoId}`] = option[campoId];
          row[`${coluna.nomeCampoPai}__${coluna.carregarCampo}`] = option[coluna.carregarCampo];
          row[`${coluna.nomeCampoPai}__${coluna.carregarCampoComplementar}`] = option[coluna.carregarCampoComplementar];
          row[coluna.nomeCampoPai][campoId] = option[campoId];
          row[coluna.nomeCampoPai][coluna.carregarCampo] = option[coluna.carregarCampo];      
          row[coluna.nomeCampoPai][coluna.carregarCampoComplementar] = option[coluna.carregarCampoComplementar];   

          const carregarCampoComplementar = this.interno.colunas.filter(x => x.campoId = 'id')[0].carregarCampoComplementar;
          if(carregarCampoComplementar && option[carregarCampoComplementar]){
            row[`${coluna.nomeCampoPai}__${carregarCampoComplementar}`] = option[carregarCampoComplementar];
          }

          if( row[coluna.carregarCampoComplementarSecundario] == null ||  row[coluna.carregarCampoComplementarSecundario] == "") {
            row[coluna.carregarCampoComplementarSecundario] = option[coluna.carregarCampoComplementarSecundario] ?? 0;
          }
        }else{
          row[campoId] =  option[campoId];
          row[coluna.carregarCampo] = option[coluna.carregarCampo];
          row[coluna.carregarCampoComplementar] = option[coluna.carregarCampoComplementar];

          if(coluna.valor=="nome" && coluna.tipo=="autocomplete"){
              row[coluna.valor] = option[coluna.valor]; 
          }
          
          if(coluna.tabela === "Exame"){
            row['__resultado'] = this.data.find(e => e.id == row.id);
          }
        }
        this.change(coluna,row);
      }
    },
    getAsyncData: debounce(function (name, coluna) {
      if (!name.length) {
          this.data = []
          return
      }
      this.isFetching = true
      this.$http.get(`/Search/${coluna.tabela}?${coluna.campoAutocomplete}=${name}&_=${new Date().getTime()}&estoqueId=${this.estoqueId}`)
          .then(({ data }) => {
              this.data = []
              data.forEach((item) => this.data.push(item))
          })
          .catch((error) => {
              this.data = []
              throw error
          })
          .finally(() => {
              this.isFetching = false
          })
    }, 500),
    consultarId(coluna,row,index) {
      if (!row[coluna.valor]){
        return;
      }
      this.isFetching = true;
      const campoId = coluna.carregarCampoId ? coluna.carregarCampoId : 'id';      
      let filtroFixo = '';
      if (coluna.filtroFixo){
        Object.keys(coluna.filtroFixo).forEach(k => {
          filtroFixo += `&${k}=${coluna.filtroFixo[k]}`;
        })
      }
      this.$http.get(`/Search/${coluna.tabela}Id?${campoId}=${row[coluna.valor]}${filtroFixo}&_=${new Date().getTime()}&estoqueId=${this.estoqueId}`)
          .then(({ data }) => {
            const campoIdComponent = coluna.campoIdComponente ? coluna.campoIdComponente : campoId;
            if (coluna.nomeCampoPai){
              row[coluna.nomeCampoPai][campoIdComponent] = data[campoIdComponent];
              row[coluna.nomeCampoPai][coluna.carregarCampo] = data[coluna.carregarCampo];
              row[coluna.nomeCampoPai][coluna.carregarCampoComplementar] = data[coluna.carregarCampoComplementar];
              row[`${coluna.nomeCampoPai}__${campoIdComponent}`] = data[campoIdComponent];
              row[`${coluna.nomeCampoPai}__${coluna.carregarCampo}`] = data[coluna.carregarCampo]; 
              row[`${coluna.nomeCampoPai}__${coluna.carregarCampoComplementar}`] = data[coluna.carregarCampoComplementar]; 

              row[coluna.carregarCampoComplementarSecundario] = data[coluna.carregarCampoComplementarSecundario];

              this.$refs[`autocomplete_${coluna.nomeCampoPai}__${coluna.carregarCampo}`][index]?.setSelected({
                [campoIdComponent]: data[campoIdComponent],
                [coluna.carregarCampo]: data[coluna.carregarCampo]
              });
              this.$refs[`autocomplete_${coluna.nomeCampoPai}__${coluna.carregarCampoComplementar}`][index]?.setSelected({
                [campoIdComponent]: data[campoIdComponent],
                [coluna.carregarCampoComplementar]: data[coluna.carregarCampoComplementar]
              });
              row[coluna.nomeCampoPai]['__resultado'] = data;
            }else{
              row[campoIdComponent] =  data[campoIdComponent];
              row[coluna.carregarCampo] = data[coluna.carregarCampo];
              row[coluna.carregarCampoComplementar] = data[coluna.carregarCampoComplementar];
              this.$refs[`autocomplete_${coluna.carregarCampo}`][index]?.setSelected({
                [campoIdComponent]: data[campoIdComponent],
                [coluna.carregarCampo]: data[coluna.carregarCampo]
              });          
              if(coluna.carregarCampoComplementar){
                this.$refs[`autocomplete_${coluna.carregarCampoComplementar}`][index]?.setSelected({
                  [campoIdComponent]: data[campoIdComponent],
                  [coluna.carregarCampoComplementar]: data[coluna.carregarCampoComplementar]
                });                    
              }
              row['__resultado'] = data;              
            }
          })
          .catch((error) => {
              this.data = []
              throw error
          })
          .finally(() => {
              this.isFetching = false
          })

    },
    getId:  debounce(function(coluna, row, index) {
      this.consultarId(coluna,row,index);
    },500),    
    calcularSubtotal(row){
      const colunasSubtotais = this.interno.colunas?.filter(c => c.tipo == 'subtotal');
      colunasSubtotais?.forEach(colunaSubtotal => {
        if (colunaSubtotal && colunaSubtotal.fn){
          row[colunaSubtotal.valor] = colunaSubtotal.fn(row);
        }
      })

      this.$forceUpdate();
    }
    ,
    getValorTotal(nomeColuna){
      let total = 0;
      this.interno.items?.forEach(i => {        
        if (i[nomeColuna] && !isNaN(i[nomeColuna])){
          total += parseFloat(i[nomeColuna]);
        }
      });

      return total;
    },
    recarregarValoresDinamicosDasColunas(items,colunas, refs){
      if (!colunas || !colunas.length) return items;

      colunas?.forEach(c => {
        if (c.carregarCampo){
          items.forEach((i,index) => {
            setTimeout(() => {
              this.consultarId(c,i,index);
            },100);
          })
        }
      })

      // items = items.filter(i => i);

      return items;
    }
  },
};
</script>

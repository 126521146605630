<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading is-full-page v-model="loading"></b-loading>
        <div class="box" v-if="!semVinculo">   
            <div class="columns is-multiline">
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.UNIDADE')">
                        <b-taginput
                            v-model="unidadesSelecionadas"
                            :data="unidadesLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.unidadesLista = this.unidades; return item;}"
                            @input="loadEstudos"                            
                            field="nome"
                            icon="label"
                            :placeholder="$t('ESTUDOS.SELECIONARUNIDADES')"
                            @typing="buscarUnidade"                            
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.DEPARTAMENTO')">
                        <b-taginput
                            v-model="departamentosSelecionados"
                            :data="departamentosLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.departamentosLista = this.departamentos; return item;}"
                            @input="loadEstudos"                            
                            field="nome"
                            icon="label"
                            :placeholder="$t('ESTUDOS.SELECIONARDEPARTAMENTOS')"
                            @typing="buscarDepartamento"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.SETOR')">
                        <b-taginput
                            v-model="setoresSelecionados"
                            :data="setoresLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.setoresLista = this.setores; return item;}"
                            @input="loadEstudos"                            
                            field="nome"
                            icon="label"
                            :placeholder="$t('ESTUDOS.SELECIONARSETORES')"
                            @typing="buscarSetor"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field :label="$t('SISTEMA.CONVENIO')">
                        <b-taginput
                            v-model="conveniosSelecionados"
                            :data="conveniosLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.conveniosLista = this.convenios; return item;}"
                            @input="loadEstudos"                            
                            field="nome"
                            icon="label"
                            :placeholder="$t('ESTUDOS.SELECIONARCONVENIOS')"
                            @typing="buscarConvenio"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
            </div> 
            <div class="columns is-multiline">
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.ACCESSIONNUMBER')">
                        <b-input :placeholder="$t('ESTUDOS.PLACEHOLDERPROCURARACCESSIONUMBER')"
                                v-model="buscaAccessionNumber"
                                icon="magnify"
                                @input="loadEstudos"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <searchIdName table="paciente" :label="$t('SISTEMA.PACIENTE')" 
                                    :id.sync="pacienteId" :camposAgendamento="[{especie:'especie', raca: 'raca', responsavel: 'responsavel'}]">
                    </searchIdName>
                </div>
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.EXECUTANTE')">
                        <b-select v-model="executanteId" expanded>
                            <option :value="todosExecutantes">{{$t('ESTUDOS.FILTRARTODOS')}}</option>
                            <template v-for="(item) in funcionariosAtivos">
                                <option v-if="executanteId == item.id" selected v-bind:key="item.id" :value="item.id">
                                    {{item.nome}}
                                </option>
                                 <option v-else v-bind:key="item.id" :value="item.id">
                                    {{item.nome}}
                                </option>
                            </template>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b>{{$t('ESTUDOS.FILTRARSTATUS')}}</b>
                    <b-select v-model="statusFiltroEstudos" expanded>
                        <option :value="todos">{{$t('ESTUDOS.FILTRARTODOS')}}</option>
                        <template v-for="(item, index) in this.statusEstudos">
                            <option v-if="statusFiltroEstudos == item.valor" selected v-bind:key="index" :value="item.valor">
                                {{item.descricao}}
                            </option>
                            <option v-else v-bind:key="index" :value="item.valor">
                                {{item.descricao}}
                            </option>
                        </template>
                    </b-select>
                </div>   
            </div>
            <div class="columns is-multiline">
                <div class="column is-3">
                    <b-field :label="$t('ESTUDOS.FILTRAPERIODO')">
                        <div class="panel">
                            <p class="panel-tabs">
                                <a :class="{ 'is-active': laudoFiltro == 'solicitacao' }" @click="laudoFiltro = 'solicitacao';loadEstudos()">{{$t('ESTUDOS.FILTRAPORSOLICITACAO')}}</a>
                                <a :class="{ 'is-active': laudoFiltro == 'estudorecebido' }" @click="laudoFiltro = 'estudorecebido';loadEstudos()">{{$t('ESTUDOS.FILTRAPORESTUDORECEBIDO')}}</a>
                                <a :class="{ 'is-active': laudoFiltro == 'retorno' }" @click="laudoFiltro = 'retorno';loadEstudos()">{{$t('ESTUDOS.FILTRAPORRETORNO')}}</a>
                            </p>
                            <div class="panel-block">
                                <periodo :dataInicial.sync="filtroLaudoDatas[0]"
                                        :dataFinal.sync="filtroLaudoDatas[1]"
                                        @changed="loadEstudos"/>
                            </div>
                        </div>
                    </b-field>
                </div>
                <div class="column is-3">
                <br />    
                        <b-switch v-model="exibeUrgente" @input="loadEstudos">
                            {{$t('ESTUDOS.URGENTE')}}
                        </b-switch>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="columns is-multiline">
                <estudo v-for="(estudo, index) in model" :key="index" :estudo="estudo" :index="index" :selecionado="estudoSelecionado.includes(estudo)" v-on:selecionarEstudo="selecionarEstudo(estudo)"
                 :setores="setoresSelecionados" :departamentos="departamentosSelecionados" :unidades="unidadesSelecionadas" :convenios="conveniosSelecionados"
                 :mostraAccessionNumber="true"></estudo>
            </div>
        </div>
    </section>
</template>

<script>
    import {  mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import estudo from '@/components/atendimento/estudo.vue'
    import periodo from '@/components/periodo.vue'
    import searchIdName from '@/components/searchIdName.vue'


    export default {
        data() {
            return {
                model: [],
                estudoSelecionado: [],
                loading: false,
                nomePaciente: null,
                buscaAccessionNumber: null,
                executanteId: null,
                funcionariosAtivos: [],
                openOfFocus: false,
                setoresLista: this.setores,
                departamentosLista: this.departamentos,
                unidadesLista: this.unidades,
                conveniosLista: this.convenios,
                exibeUrgente: false,
                setoresSelecionados: [],
                departamentosSelecionados: [],
                unidadesSelecionadas: [],
                conveniosSelecionados: [],
                pacienteId: null,
                laudoFiltro: !this.semVinculo ? localStorage.laudoFiltro || 'solicitacao' : '',
                filtroLaudoDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                semVinculo: false,
                statusEstudos: [],
                unidades: [],
                statusFiltroEstudos: null,
                todos: null,
                todosExecutantes: null
            }
        },
        computed: {
            ...mapState([                
                'componentes',
                'usuario'
            ]), 
            titleStack() {
                return [
                    'Análise',
                    this.$route?.query?.semVinculo
                        ? this.$t('MENUANALISE.ESTUDOSSEMVINCULO')
                        : this.$t('MENUANALISE.ESTUDOSTITULO')
                ]
            }, 
            ...mapState([
                'unidadeId'                
            ]),              
        },
        watch:{
            statusFiltroEstudos: function() {
                this.loadEstudos()
            },
            executanteId: function(){
                this.loadEstudos();                
            },
            pacienteId: function(){
                this.loadEstudos();
            },
        },
        components: {
            titlebar,
            estudo,
            periodo,
            searchIdName,
        },
        created() {
            this.executanteId = this.usuario.funcionarioId;
            this.loadSetores()
            this.loadDepartamentos()
            this.loadUnidades()
            this.loadConvenios()
            this.loadEstudos()
        },
        mounted () {
            if(this.$route?.query?.semVinculo) {
                this.semVinculo = true;
            }
            else {
                this.componentes.sidebarIsOpened = false;
            }

            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
            .then(res => {
                if (res.data != null) {
                    this.funcionariosAtivos = res.data;

                    if(this.funcionariosAtivos.filter(f=> f.id == this.usuario.funcionarioId).length>0){
                        this.executanteId = this.usuario.funcionarioId;    
                    }else{
                        this.executanteId= null;
                    }
                                        
                }
            })

            this.$http.get('/api/search/ListarStatusEstudos')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusEstudos = data;
                }
            });

        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },  
        methods: {         
            loadEstudos(){

                const params = [];

                if(this.$route?.query?.semVinculo){
                    params.push('semVinculo=true');
                }else{
                    if(this.$route.query.unidadeId){                        
                        params.push(`unidadeId=${this.$route?.query?.unidadeId}`);
                    }else{                        
                        params.push(`unidadeId=${this.unidadeId}`);
                    }
                }                
                if(this.$route?.query?.dataInicial && this.$route?.query?.dataFinal){
                    this.filtroLaudoDatas[0] = this.$route?.query?.dataInicial;
                    this.filtroLaudoDatas[1] = this.$route?.query?.dataFinal
                }

                localStorage.laudoFiltro = this.laudoFiltro;
                
                if (this.buscaAccessionNumber) params.push(`buscaAccessionNumber=${this.buscaAccessionNumber}`);
                
                if(this.setoresSelecionados.length > 0){
                    params.push('setorId=' + this.setoresSelecionados.map(item => item.id).join());
                }

                if(this.departamentosSelecionados.length > 0){
                    params.push('departamentoId=' + this.departamentosSelecionados.map(item => item.id).join());
                }

                if(this.unidadesSelecionadas.length > 0){
                    params.push('filtroUnidadesId=' + this.unidadesSelecionadas.map(item => item.id).join());
                }

                if(this.conveniosSelecionados.length > 0){
                    params.push('convenioId=' + this.conveniosSelecionados.map(item => item.id).join());
                }

                if(this.pacienteId != null){
                    params.push(`nomePaciente=${this.pacienteId}`);
                }

                if (this.exibeUrgente) params.push(`filtraUrgentes=${this.exibeUrgente}`);

                if (this.laudoFiltro) {
                    params.push(`laudoFiltro=${this.laudoFiltro}` +
                        '&dataInicial=' + this.$moment(this.filtroLaudoDatas[0]).format('YYYY-MM-DDTHH:mm:ss') +
                        '&dataFinal=' + this.$moment(this.filtroLaudoDatas[1]).format('YYYY-MM-DDTHH:mm:ss'))
                }
                if(this.executanteId != null) {
                    params.push(`executanteId=${this.executanteId}`);
                }
                

                if (this.statusFiltroEstudos != null) params.push(`statusEstudos=${this.statusFiltroEstudos}`);
                

                this.loading = true
                this.$http.get(`/api/analitico/estudos?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.model = []
                        this.loading = false
                        throw error
                    })  
            },
            selecionarEstudo(estudo) {
                if (this.estudoSelecionado == null) {
                    this.estudoSelecionado = [estudo]
                } else {
                    if (this.estudoSelecionado.includes(estudo)) {
                        this.estudoSelecionado = this.estudoSelecionado.filter(function(x) { return x != estudo })
                    } else {
                        this.estudoSelecionado.push(estudo)
                    }
                }
            },
            loadSetores(){
                this.$http.get('/api/search/setores')
                    .then(res => {
                        this.setores = res.data;
                    });
   
            },
            loadDepartamentos(){
                this.$http.get('/api/search/departamentos')
                    .then(res => {
                        this.departamentos = res.data;
                    });
   
            },
            loadUnidades(){
                this.$http.get('/api/search/unidades')
                    .then(res => {
                        this.unidades = res.data;    
                        
                        if(this.$route.query.unidadeId)
                            this.unidadesSelecionadas.push(this.unidades.filter(u => u.id == this.$route.query.unidadeId)[0]);                
                                                
                    });
   
            },
            loadConvenios(){
                this.$http.get('/api/search/convenios')
                    .then(res => {
                        this.convenios = res.data;
                    });
   
            },
            buscarSetor(text) {
                this.setoresLista = this.setores;
                this.setoresLista = this.setoresLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarDepartamento(text) {
                this.departamentosLista = this.departamentos;
                this.departamentosLista = this.departamentosLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarUnidade(text) {
                this.unidadesLista = this.unidades;
                this.unidadesLista = this.unidadesLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarConvenio(text) {
                this.conveniosLista = this.convenios;
                this.conveniosLista = this.conveniosLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },               
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('REGRAAGENDAMENTOCADASTRO.SALVANDOAGENDAMENTO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('REGRAAGENDAMENTOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column column is-one-quarter">
                                    <ValidationProvider name="model.slot" v-slot="{ errors, valid }">
                                        <b-field :label="$t('REGRAAGENDAMENTOCADASTRO.SLOT')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.slot"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                                                               
                            <searchIdName class="column"  table="tipoagendamento" :label="$t('REGRAAGENDAMENTOCADASTRO.TIPOAGENDAMENTO')" :id.sync="model.tipoAgendamento.id">
                                <slot>
                                    <b-button icon-left="plus-box"
                                        tag="router-link"
                                        :to="{ name: 'tipoagendamento', query: { retorno: true },query: { retornarTipoAgendamento:true }}"
                                        target="_blank" ></b-button>
                                </slot>
                            </searchIdName>  

                            <searchIdName class="column"  table="FuncionarioComCrm" :label="$t('REGRAAGENDAMENTOCADASTRO.FUNCIONARIO')" :id.sync="model.funcionario.id">
                            </searchIdName>                                                       
                                                                                    
                        </article>


                        <article  class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('REGRAAGENDAMENTOCADASTRO.REGRAS')}}
                            </div>
                            <div class="panel-block">
                                <table class="table is-bordered is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th class="is-1"></th>                                                                                      
                                            <th>{{$t('REGRAAGENDAMENTOCADASTRO.EXAME')}}</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.exames?.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>                                                                                        
                                            <td><searchIdName :id.sync="item.exameId" :nome.sync="item.exameNome"  table="Exame"></searchIdName></td>
                                        </tr>
                                    </tbody>
                                </table>                                
       
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('REGRAAGENDAMENTOCADASTRO.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>                                                                 

                                                  

                    </div> 
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.conjuntoCompleto">
                                            {{$t('REGRAAGENDAMENTOCADASTRO.CONJUNTOCOMPLETO')}}
                                        </b-switch>
                                    </div>
                                </div>
                 
                            </article>
            
                        </article>
                    </div>                                         
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('regras-agendamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('REGRAAGENDAMENTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('REGRAAGENDAMENTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'    

    export default {
        components: {
            titlebar,
            searchIdName            
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    slot: 1,
                    conjuntoCompleto:false,
                    tipoAgendamento:{
                     id: null,   
                     nome:null
                    },
                    funcionario:{
                     id: null,   
                     nome:null
                    },                    
                    exames:[]                                                
                },                                
                salvandoDados: false,                
            }
        },
        created() {

            if(this.$route.params.id){
                this.$http.get('/api/manutencao/regraagendamento?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;

                        if(!this.model.tipoAgendamento)
                            this.model.tipoAgendamento = {id : null, nome: null};

                        if(!this.model.funcionario)
                             this.model.funcionario = {id : null, nome: null};

                        this.model.exames.forEach((e) => { e.show = true; });
                                                
                    });
            }

            document.addEventListener('visibilitychange', this.verificaRetornar, false);            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REGRAAGENDAMENTOCADASTRO.MANUTENCAO'),
                    this.$t('REGRAAGENDAMENTOCADASTRO.REGRAAGENDAMENTO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            addItem() {
                
                if(this.model.exames == null )
                    this.model.exames = [];

                this.model.exames.push({
                    id:0,
                    exameId:null,
                    exameNome: null,
                    show:true                    
                })
            },  
            
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.exames.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },                              
                                
            verificaRetornar() {                                   
                if(localStorage.retornarTipoAgendamento > 0 && this.model.tipoAgendamentoId ==null){                                        
                    this.model.tipoAgendamento.id = window.localStorage.getItem('retornarTipoAgendamento');                    
                    localStorage.retornarTipoAgendamento = null;
                }                                                         
            },               
                                
            onSubmit() {
                this.salvandoDados = true;
                let todosExamesPreenchidos = true;                


                if(this.model.id == null){
                    this.model.id = 0
                }

                                
                this.model.exames?.filter(i => i.show===true).forEach((e) => {                                                     
                    if(!e.exameId){
                        todosExamesPreenchidos= false;
                     }
                });

                if(this.model.funcionario && this.model.funcionario.id <= 0 ){
                    this.model.funcionario= null;
                }

                if(this.model.tipoAgendamento.id == null) {
                    this.model.tipoAgendamento.id = 0;
                }
                                       

                if(todosExamesPreenchidos && (this.model.tipoAgendamento.id || this.model.funcionario != null) && this.model.slot>=0 && this.model.nome ){
                    this.$http.post(`/api/manutencao/regraagendamento`, this.model)
                            .then((res) => {
                                this.salvandoDados = false;

                                this.$router.back();
                                                            
                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('REGRAAGENDAMENTOCADASTRO.ATENCAO'),
                        message: this.$t('REGRAAGENDAMENTOCADASTRO.CAMPOSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });                    
                    this.salvandoDados = false;
                    return null;
                }
                
            },
        }
    }
</script>
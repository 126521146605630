<template>
    <section>
        <div class="box" >
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <label><strong>{{$t('LOTES.CODIGOBARRAS')}}</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <b-input v-model="amostraCodigoDeBarras" ref="amostraCodigoDeBarras" id="amostraCodigoDeBarras"></b-input>
                                </div>
                                <div class="control">
                                    <b-button native-type="submit" :loading="loading">
                                        {{$t('LOTES.INCLUIR')}}
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="level-right">
                    <b-button native-type="button" class="button is-warning" @click="incluirPendencias()" :loading="loading">{{$t('LOTES.INCLUIRPENDENCIA')}}</b-button>
                </div>
            </article>
        </div>

        <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

            <template>
                <b-table-column v-slot="props" field="Contador" :label="$t('LOTES.CONTADOR')">
                    <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                </b-table-column>

                <b-table-column v-slot="props" field="Amostra" :label="$t('LOTES.AMOSTRA')">
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Material" :label="$t('LOTES.MATERIAL')">
                    {{ props.row.materialNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Conservante" :label="$t('LOTES.CONSERVANTE')">
                    {{ props.row.conservanteNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Exames" :label="$t('LOTES.EXAMES')">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>
            </template>
        </b-table>
        
        <div class='box' style="margin-top: 10px;">
            <div class="columns">
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.TEMPERATURA')}} (C°)</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.LATITUDE')}}</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.LONGITUDE')}}</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
            </div>
        </div>
        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-primary" icon-left="check-circle" @click="enviarLote();" :loading="loading">
                        {{$t('SISTEMA.ENVIAR')}}
                    </b-button>

                </p>
            </div>
        </nav>
    </section>    
</template>


<script>
    
    export default {
        data() {
            return {
                amostras: [],
                amostraCodigoDeBarras: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left'
            }
        },
        created() {
            this.$getLocation({})
                .then(coordinates => {
                    this.latitude = coordinates.lat
                    this.longitude = coordinates.lng
                });
        },
        methods:{
            incluirAmostra(){
                
                if(this.amostraCodigoDeBarras !== "" && this.amostraCodigoDeBarras !== null){
                    this.loading = true;

                    if(this.amostras.length){
                        for(const amostra of this.amostras){
                            if(amostra.amostraCodigoDeBarras == this.amostraCodigoDeBarras){
                                this.$buefy.dialog.alert({
                                    title: this.$t('LOTES.AMOSTRASELECIONADA'),
                                    message:  this.$t('LOTES.AMOSTRAJAINCLUIDA', { msg:  this.amostraCodigoDeBarras }), 
                                    type:'is-warning',
                                    hasIcon: true
                                });
                                this.loading = false;
                                return;
                            }
                        }
                    }

                    this.$http.get('/api/portal/EnviarLoteIncluir?codigoDeBarras=' + this.amostraCodigoDeBarras)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {
                                this.amostras.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }
                        }
                        else {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.',
                                type: 'is-warning',
                                queue: false
                            });
                        }
    
                        this.$refs.amostraCodigoDeBarras.focus();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            queue: false
                        });
                        this.loading = false;
                    }); 
    
                    this.amostraCodigoDeBarras = "";
                }
            },
            incluirPendencias(){
                this.loading = true;
                
                this.$http.get('/api/portal/EnviarLoteIncluirPendencias')
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            this.amostras.push(data[i]);
                            this.checkedRows.push(data[i]);
                        }
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.',
                            type: 'is-warning',
                            queue: false
                        });
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error,
                        type: 'is-danger',
                        queue: false
                    });
                    this.loading = false;
                }); 

                this.amostraCodigoDeBarras = "";
            },
            enviarLote(){
                this.loading = true;

                const enviarLote = {
                    amostras: this.checkedRows, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude
                };

                this.$http.post(`/api/portal/EnviarLote`, enviarLote )
                    .then(({ data }) => {
                        this.loading = false;
                        if(data != null){
                            const loteId = data;
                            this.imprimirLote(loteId);
                        }
                    })
                    .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error,
                        type: 'is-danger',
                        queue: false
                    });
                        this.loading = false;
                    }); 

                this.amostras = [];
                this.checkedRows = [];
            },
            imprimirLote(loteId){
                window.open('/Guia/comprovanteLote?id='+loteId + '&filtroLote=' + true);
            },
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar> 
        
        <div class='box' v-if="pesquisa != null">
            <div class="panel">
                <div class="panel-heading background-2">
                    <div class="level">
                        <div class="level-left">
                            <strong>{{pesquisa.nome}}</strong>
                        </div>
                    </div>
                </div>
                <div class="panel-block columns is-multiline is-justified-between">
                    <div class="column is-12 is-fullwidth" v-for="(pergunta, idxperg) in pesquisa.perguntas" :key="idxperg">
                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" >
                            <tr v-for="(perguntaItem, idxpergitem) in pergunta.perguntaItens" :key="idxpergitem">
                                <th width="40%">
                                    <span v-if="idxpergitem == 0">{{ pergunta.texto }}</span>
                                </th>
                                <td v-if="perguntaItem.aceitaDescritivo">
                                    <b-input type="text" v-model="perguntaItem.resposta"></b-input>
                                </td>
                                <td v-else-if="perguntaItem.aceitaData">
                                    <b-datepicker editable v-model="perguntaItem.resposta"></b-datepicker>
                                </td>
                                <td v-else-if="perguntaItem.somenteNumerico">
                                    <b-input type="number" v-model="perguntaItem.resposta"></b-input>
                                </td>
                                <td v-else-if="perguntaItem.somenteInteiro">
                                    <b-input type="text" v-model="perguntaItem.resposta"  @input="handleInput(idxperg, idxpergitem)"></b-input>
                                </td>
                                <td v-else>
                                    <b-radio v-model="pergunta.resposta"
                                        :name="'lista_' + index + 'pergunta_' + pergunta.id"
                                        :native-value="perguntaItem.id">
                                        {{ perguntaItem.texto }}
                                    </b-radio>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                native-type="button"
                                :loading="salvandoDados"
                                @click="salvar()"
                                icon-left="check-circle">
                        {{$t('PESQUISASATISFACAO.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        {{$t('PESQUISASATISFACAO.VOLTAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>    
</template>


<script>
    import { mapActions, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                salvandoDados: false,
                id: this.$route.params.id,
                pesquisa: null
            }
        },
        components: {
            titlebar        
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ]),            
            titleStack() {
                return [
                    this.$t('PESQUISASATISFACAO.TITULOPORTAL'),
                    this.$t('PESQUISASATISFACAO.TITULOPESQUISA'),
                    this.id
                ]
            }
        },
        mounted() {
            this.salvandoDados = true
                this.$http.get(`/api/portal/ResponderPesquisa?id=${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.pesquisa = data;
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
        },
        methods:{
            async salvar() {
                const formData = new FormData();

                this.pesquisa.perguntas.forEach((pergunta, i) => {

                    formData.append("respostas["+ i +"].Pesquisa.Id", this.pesquisa.id);
                    formData.append("respostas["+ i +"].Pergunta.Id", pergunta.id);

                    pergunta.perguntaItens.forEach((perguntaItem, j) => {
                        if(perguntaItem.aceitaData){
                            perguntaItem.resposta = this.$moment(perguntaItem.resposta).format()
                        }

                        if(perguntaItem.resposta != null && perguntaItem.resposta != '' && perguntaItem.resposta != undefined)
                        {
                            formData.append("respostas["+ i +"].Descritivo", perguntaItem.resposta);
                        }

                        if(!perguntaItem.aceitaDescritivo && !perguntaItem.aceitaData && !perguntaItem.aceitaArquivo && !perguntaItem.somenteNumerico  && !perguntaItem.somenteInteiro){
                            formData.append("respostas["+ i +"].PerguntaItem.Id", pergunta.resposta);
                        }
                        else {
                            formData.append("respostas["+ i +"].PerguntaItem.Id", perguntaItem.id);
                        }
                    })
                });

                this.salvandoDados = true;                
                
                this.$http.post('/api/portal/GravarRespostas', formData)
                    .then(res => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Pesquisa respondida com sucesso!',
                            type: 'is-success'
                        })

                        this.$router.back();
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
                    
                    
            },
            handleInput(indexPergunta, indexPerguntaItem) {
                console.log(this.pesquisa);
                const resposta = this.pesquisa.perguntas[indexPergunta].perguntaItens[indexPerguntaItem].resposta;

                // Remova vírgulas ou pontos do valor para garantir que seja um número inteiro
                if (resposta !== null && (resposta.toString().includes(',') || resposta.toString().includes('.'))) {
                    this.pesquisa.perguntas[indexPergunta].perguntaItens[indexPerguntaItem].resposta =  resposta.includes(',') 
                        ? resposta.replace(',','') 
                        : resposta.replace('.','');
                }
            },
        }
    }
</script>
<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ this.$t('RECEBIMENTOAMOSTRAS.TITULO') }}</p>
        </header>
        <section class="modal-card-body">
            <p v-html="msgAmostrasNomes"></p>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-flex-end">
            <b-button type="is-success" v-if="isInRole('guia-receber-amostra-fora-lote')" @click="receber">{{ this.$t('RECEBIMENTOAMOSTRAS.RECEBER') }}</b-button>
            <b-button type="is-danger" @click="cancelar">{{ this.$t('RECEBIMENTOAMOSTRAS.CANCELAR') }}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    .modal-card {
        max-width: 500px; 
    }
</style>

  <script>

  import { mapGetters, mapState } from 'vuex'   

  export default {
    props: {
        amostraLote: Array,
        amostraCodigoDeBarras: Array,
        url: String,
    },
    computed: {
        ...mapGetters([
            'isInRole'
        ])
    },
    data() {
      return {
        msgAmostrasNomes: '',
      };
    },
    mounted() {
        this.msgAmostrasNomes = this.$t('RECEBIMENTOAMOSTRAS.AMOSTRANAOCONSTA', {'0': this.amostraCodigoDeBarras, '1': this.amostraLote.amostraLote});
    },
    methods: {
      receber() {
        this.$emit('recebimentoDeAmostras', this.amostraCodigoDeBarras, this.url);
        this.$emit('close');
      },
      cancelar() {
        this.$emit('close');
      },
    },
  };
  </script>
<template>
    <section>
            <div class="notification is-info" v-if="loading">
                <button type="button" class="delete"></button>
                A tabela de preços está sendo salva, este processo pode levar alguns minutos.
            </div>                
            <div class="columns is-multiline">

                <div class="column  tile is-12">
                    <div class="column  is-6 ">
                    <small><b>Importante:</b> o arquivo deverá conter a primeira linha com títulos.<br>
                           A tabela precisará ter 1 coluna com os preços (numérico) e 1 coluna com o código do exame.</small>                    
                    </div>
                    <b-field class="file column is-6 " >
                        <b-upload accept=".xls,.xlsx" @input="incluirAnexo" v-model="arquivo" expanded >
                            <a class="button is-primary is-fullwidth">
                            <b-icon icon="upload"></b-icon>                        
                            <span>{{ (arquivo) ? arquivo.name : "Importar Excel" }}</span>
                            </a>
                        </b-upload>

                    </b-field>                     
                </div>
               
            </div>
            
            <hr v-if="model!=null">    
            <div v-if="model!=null" class="columns is-multiline">                
                <div class="column  tile is-12">  
                    
                    <b-field  class="column is-6" label="Nome da Nova Tabela" >
                        <b-input type="string" v-model="filtro.nome"  ></b-input>
                    </b-field>                     
                
                    <b-field  class="column is-6" label="Selecione o tipo da tabela de preço" >
                        <b-select  v-model="filtro.tipo" expanded  >
                            <option value="0" checked > Moeda</option>
                            <option value="1" > AMB</option>
                            <option value="2" > CBHPM</option>   
                            <option value="3" > Custo</option>   
                        </b-select>                                                                                 
                    </b-field>
                </div>

                <div class="column  tile is-12">  

                    <b-field class="column is-6" label="Selecione o tipo de código dos exames" >
                        <b-select  v-model="filtro.tipoCodigo" expanded  >
                            <option v-for="(item, index) in this.model.tiposCodigos" v-bind:key="index" 
                                :value="item.value">{{item.text}}</option>  
                        </b-select>                                                  
                    </b-field>
                </div>                
                                   
                <div class="column  tile is-12">  
                    <b-field class="column is-12" label="Primeira linha do arquivo:" >
                        <div class="column is-12 center is-fullwidth">
                            <b-tag size="is-large" v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index">
                                {{ item }}
                            </b-tag>                                      
                        </div>
                    </b-field>                            
                </div>
                
                <div v-show="filtro.tipo==0 || filtro.tipo == 3" class="column  tile is-12">  
                    <b-field  class="column is-6" :label="'Selecione a coluna do arquivo referente aos Preços'" >
                        <b-select  v-model="filtro.preco" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>
                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente aos Códigos" >
                        <b-select  v-model="filtro.codigo" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>   
                        </b-select>                                                  
                    </b-field>                                        
                </div>   
                
                <div v-show="filtro.tipo==1" class="column  tile is-12">  
                    <b-field  class="column is-6" :label="'Selecione a coluna do arquivo referente ao Valor US'" >
                        <b-select  v-model="filtro.usAMB" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>
                    
                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente aos Códigos" >
                        <b-select  v-model="filtro.codigoAMB" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>
                </div>   

                
                <div v-show="filtro.tipo==2" class="column  tile is-12">  
                    <b-field  class="column is-6" :label="'Selecione a coluna do arquivo referente ao Percentual'" >
                        <b-select  v-model="filtro.percentualCBHPM" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>
                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente ao Porte" >
                        <b-select  v-model="filtro.porteCBHPM" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>      
                                                                       
                </div>       

               <div v-show="filtro.tipo==2" class="column  tile is-12">    

                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente ao Custo Operacional" >
                        <b-select  v-model="filtro.custoCBHPM" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field> 

                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente aos Códigos" >
                        <b-select  v-model="filtro.codigoCBHPM" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>                                                                           

                </div>                                                  
                

                <div class="column  tile is-12">  
                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente aos Bloqueados" >
                        <b-select  v-model="filtro.bloqueados" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>   
                        </b-select>                                                  
                    </b-field>

                    <b-field v-if="filtro.tipo != 3"  class="column is-6" label="Selecione a coluna do arquivo referente aos Negociados" >
                        <b-select  v-model="filtro.negociados" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>                                        
                </div>                                
               
                <div v-show="filtro.tipo==1" class="column  tile is-12">  
                    <b-field  class="column is-6" label="Selecione a coluna do arquivo referente ao Valor Filme" >
                        <b-select  v-model="filtro.filmeAMB" expanded  >
                            <option value="" checked></option>
                            <option v-for="(item, index) in this.model.primeiraLinha" v-bind:key="index" 
                                :value="index">{{item}}</option>  
                        </b-select>                                                  
                    </b-field>    
                </div>  
            </div>            
                     
            <hr>
            <div  class="has-text-centered">
                <b-button v-if="model!=null" type="is-success" :loading="loading" icon-left="check-circle"  
                    @click="salvar()">Importar</b-button>
            </div>
      
    </section>
</template>

<script>
    import { mapMutations, mapState, mapGetters } from 'vuex'
    
    export default {
        data() {
            return {
                model:null,
                formData: new FormData(),                
                arquivo:null,                
                loading: false,
                filtro:{                    
                    nome:null,
                    tipo:0,
                    tipoCodigo:null,
                    preco:null,
                    codigo:null,
                    usAMB: null,
                    filmeAMB: null,
                    codigoAMB: null,
                    percentualCBHPM: null,
                    porteCBHPM: null,
                    custoCBHPM: null,
                    codigoCBHPM: null,
                    bloqueados: null,
                    negociados: null                    
                },                                    
            }
        },
        methods: {
           
            incluirAnexo(file) {
                                                      
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Arquivo '+ file.name + ' carregado com sucesso!',
                    type: 'is-success'
                })

                this.enviar();
                    

            },            
            salvar(){

                this.loading = true;

                this.formData = new FormData();
                this.formData.append('arquivo',this.arquivo, this.arquivo.name);
                
                const params = [];

                if(this.filtro.nome){
                    params.push(`nome=${this.filtro.nome}`);
                }  
                if(this.filtro.tipo != null){
                    params.push(`tipo=${this.filtro.tipo}`);
                }  
                if(this.filtro.tipoCodigo != null){
                    params.push(`tipoCodigo=${this.filtro.tipoCodigo}`);
                }  
                if(this.filtro.preco != null){
                    params.push(`preco=${this.filtro.preco}`);
                } 
                if(this.filtro.usAMB != null){
                    params.push(`usAMB=${this.filtro.usAMB}`);
                } 
                if(this.filtro.filmeAMB != null){
                    params.push(`filmeAMB=${this.filtro.filmeAMB}`);
                }   
                if(this.filtro.codigoAMB != null){
                    params.push(`codigoAMB=${this.filtro.codigoAMB}`);
                }  
                if(this.filtro.codigo != null){
                    params.push(`codigo=${this.filtro.codigo}`);
                }                                                                                
                if(this.filtro.percentualCBHPM != null){
                    params.push(`percentualCBHPM=${this.filtro.percentualCBHPM}`);
                }                
                if(this.filtro.porteCBHPM != null){
                    params.push(`porteCBHPM=${this.filtro.porteCBHPM}`);
                }                
                if(this.filtro.custoCBHPM != null){
                    params.push(`custoCBHPM=${this.filtro.custoCBHPM}`);
                }                
                if(this.filtro.codigoCBHPM != null){
                    params.push(`codigoCBHPM=${this.filtro.codigoCBHPM}`);
                }                
                if(this.filtro.bloqueados != null){
                    params.push(`bloqueados=${this.filtro.bloqueados}`);
                }   
                if(this.filtro.negociados != null){
                    params.push(`negociados=${this.filtro.negociados}`);
                }                                    
                                                
                this.$http.post('/api/manutencao/ImportarTabelaPreco?'+ params.join('&'), this.formData)
                    .then(res => res.data)
                    .then(data => {                         
                        this.loading = false;                          
                        this.$router.push({ name: 'tabelasprecos'});
                           
                    }, error => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error.message,
                            type: 'is-danger'
                        });

                        this.loading = false;     
                    })     
            },
            enviar(){
                this.loading = true;

                if(!this.arquivo){

                    this.$buefy.toast.open({
                        message: "Insira o arquivo !",
                        type: 'is-warning'
                    });
                    this.loading = false;                        

                }
                
                this.formData.append('arquivo',this.arquivo, this.arquivo.name);

                this.$http.post('/api/manutencao/ImportarTabelaPreco', this.formData)
                    .then(res => res.data)
                    .then(data => { 
                        this.model = data;
                        this.loading = false;  
                        return null;
                           
                    }, error => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error.message,
                            type: 'is-danger'
                        });

                        this.loading = false;     
                    })                
                                                                                       
            },            
           
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"> </titlebar>
        <b-loading :active.sync="salvandoDados"></b-loading>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">                    
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">                            
                            <div class="columns">                                
                                <div class="column is-4">
                                    <ValidationProvider name="Desconto" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ORCAMENTOCADASTRO.DESCONTO')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input v-model="model.desconto" @input="recalcularTotal()"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.paciente.id" table="paciente" :label="$t('SISTEMA.PACIENTE')"
                                    :campos="[{nome:'Data de Nascimento', campo: 'dataDeNascimento'}]">
                                <slot>
                                    <b-button icon-left="plus-box"
                                              tag="router-link"
                                              target="_blank"
                                              :to="{ name: 'paciente', params: { return: true }, query: { retornarPaciente:true} }"
                                              :title="$t('ORCAMENTOCADASTRO.INCLUIRPACIENTE')"></b-button>
                                </slot>                                
                                </searchIdName>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.convenio.id" @item="escolheuConvenio" table="convenio" :label="$t('SISTEMA.CONVENIO')"/>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.medico.id" table="medico" :label="$t('SISTEMA.SOLICITANTE')">
                                <slot>
                                    <b-button icon-left="plus-box"
                                              tag="router-link"
                                              target="_blank"
                                              :to="{ name: 'medico',  params: { return: true },query: { retornarMedico:true}  }"
                                              :title="$t('ORCAMENTOCADASTRO.INCLUIRMEDICO')"></b-button>
                                </slot>          
                                </searchIdName>
                            </div>                            
                            <div class="columns">                                
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="Data de Emissao" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ORCAMENTOCADASTRO.DATAEMISSAO')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker v-model="model.emissao" trap-focus open-on-focus></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="data de validade" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ORCAMENTOCADASTRO.DATAVALIDADE')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker editable v-model="model.validade" trap-focus open-on-focus></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('ORCAMENTOCADASTRO.EXAME')}}</label>
                                            <span><b>{{$t('ORCAMENTOCADASTRO.INCLUIREXAME')}}</b></span>
                                            <div class="columns panel-block">

                                                <b-autocomplete class="column is-8 is-outlined is-fullwidth" :data="data"
                                                                ref="autocomplete"
                                                                v-model="autocomplete"
                                                                field="exameNome"
                                                                :loading="isFetching"
                                                                @typing="getAsyncExame"
                                                                icon="magnify"      
                                                                :readonly="model.guia != null && model.guia.id != null && model.guia.id != 0"                              
                                                                :disabled="model.guia != null && model.guia.id != null && model.guia.id != 0"                                    
                                                                @select="selectItem">
                                                    <template slot="empty">
                                                        {{$t('ORCAMENTOCADASTRO.NENHUMEXAME')}}
                                                    </template>
                                                    <template slot-scope="props">
                                                        <div class="media is-8" :style="props.option.bloqueado ? 'border: 1px solid red; background-image: none !important; padding:5px 20px' : null">
                                                            <div class="media-left">
                                                                <span class="tag is-pulled-right is-light">{{ props.option.exameId }}</span>
                                                            </div>
                                                            <div class="media-content">
                                                                {{ props.option.exameNome }}
                                                                <br>
                                                                <small>
                                                                    <span>{{props.option.exameApelido}}</span>
                                                                </small>
                                                                <br>
                                                                <span v-if="props.option.bloqueado" class="tag is-pulled-left is-light"><b>BLOQUEADO: </b>{{props.option.motivoBloqueio }}</span>
                                                            </div>
                                                            <div class="media-right">
                                                                <b>{{$t('ORCAMENTOCADASTRO.MATERIAL')}}: </b> {{ props.option.materialNome }} <br>
                                                                <b>{{$t('ORCAMENTOCADASTRO.CONSERVANTE')}}: </b> {{ props.option.conservanteNome }} <br>
                                                                <b>{{$t('ORCAMENTOCADASTRO.CODIGO')}}: </b> {{ props.option.exameCodigo }} 
                                                            </div>
                                                        </div>
                                                    </template>
                                                </b-autocomplete>

                                                <div class="column is-1 is-outlined is-fullwidth">
                                                    <b-dropdown native-type="button" :triggers="['hover']" aria-role="list" expanded>
                                                        <button type="button" native-type="button" class="button is-info is-fullwidth" slot="trigger">
                                                            <b-icon icon="menu-down"></b-icon>
                                                        </button>
                                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Apelido'">{{$t('ORCAMENTOCADASTRO.APELIDO')}}</b-dropdown-item>
                                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Nome'">{{$t('ORCAMENTOCADASTRO.NOME')}}</b-dropdown-item>
                                                    </b-dropdown>
                                                </div>

                                                <b-button v-if="gruposExames.length > 0" type="button" class="column is-3 is-success" 
                                                    @click.prevent.stop="grupos = !grupos">
                                                    {{$t('ORCAMENTOCADASTRO.GRUPOEXAME')}}
                                                </b-button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-table :data="model.exames" striped hoverable>
                                        <template >                                            
                                            <b-table-column v-slot="props" field="" label="">
                                                <b-button
                                                    icon-left="close"
                                                    type="is-danger"
                                                    @click.prevent.stop="removerExame(props.row)"
                                                ></b-button>
                                            </b-table-column>

                                            <b-table-column v-slot="props" field="exameApelido" :label="$t('ORCAMENTOCADASTRO.APELIDO')">
                                                {{ props.row.exameApelido }}
                                            </b-table-column>

                                            <b-table-column v-slot="props" field="examePreco" :label="$t('ORCAMENTOCADASTRO.PRECO')">
                                                {{ props.row.examePreco | currency  }}
                                            </b-table-column>
                                        </template>
                                        <template slot="footer">
                                            <th class="is-hidden-mobile">{{$t('ORCAMENTOCADASTRO.TOTALEXAMES')}}: {{model.exames.length}}</th>
                                            <th class="is-hidden-mobile"></th>
                                            <th class="is-hidden-mobile">
                                                {{ totalExamesDesconto | currency }}                                                  
                                            </th>
                                        </template>
                                        </b-table>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('ORCAMENTOCADASTRO.OBSERVACOES')}}</label>
                                        <div class="control">
                                            <textarea maxlength="2000" class="textarea" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'Orcamento'" :chave="model.id" v-if="model.id"></historico-alteracoes>

                            <div class="panel-block" v-if="model.id">
                                <b-dropdown native-type="button" :triggers="['hover']" aria-role="list" expanded>
                                    <button type="button" native-type="button" class="button is-info is-fullwidth" slot="trigger">
                                        <span>{{$t('ORCAMENTOCADASTRO.IMPRIME')}}</span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </button>
                                    <b-dropdown-item aria-role="listitem" @click="modeloOrcamento(modelo.nome)" v-for="(modelo, x) in model.orcamentoModelos" v-bind:key="x">{{modelo.nome}}</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="enviarEmail()">{{$t('ORCAMENTOCADASTRO.ENVIAREMAIL')}}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <br>
                            <div class="panel-block" v-if="new Date(model.validade) < Date.now() && model.guia.id == null">
                                <b-button type="button is-tag is-danger" class="button is-danger is-fullwidth">{{$t('ORCAMENTOCADASTRO.ORCAMENTOEXPIRADO')}}</b-button>
                            </div>
                            <div class="panel-block" v-show="model.id != null && model.id != 0" >
                                <b-button type="is-success" class="button is-success is-fullwidth" v-if="model.id != null && model.id != 0 && (model.guia == null || model.guia.id == null || model.guia.id == 0) && isInRole('orcamento-criaGuia') && new Date(model.validade) >= Date.now()" 
                                      :loading="salvandoDados"
                                      native-type="button"
                                      icon-left="check-circle"
                                      @click="criarGuia()">
                                    {{$t('ORCAMENTOCADASTRO.CRIARGUIA')}}
                                </b-button>
                                <b-button expanded v-else-if="model.id != null && model.id != 0 && model.guia != null && model.guia.id != null && model.guia.id != 0"	
                                        tag="router-link"                          
                                        :to="{ name: 'guia', params: {id: model.guia.id} }"                          
                                        type="is-primary"> {{model.guia.id}}
                                </b-button>  
                            </div>
                            <br>
                            <div class="box" v-if="grupos">
                                <article style="cursor: pointer;" class="box is-info column is-12" @click="incluirGrupo(grupo)" v-for="(grupo, x) in gruposExames" v-bind:key="x">
                                    <label class="label has-text-centered">{{grupo.nome}}</label>
                                    <div class="columns is-multiline" >
                                        <div class="column" v-for="(item, x) in grupo.grupoItens" v-bind:key="x">
                                            <small>{{item.exame.apelido}}</small>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-if="model.id == 0 || (model.validadeInicial > Date.now() && model.validadeInicial !=null )"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('ORCAMENTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('ORCAMENTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import debounce from 'lodash/debounce'
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    imprimirOrcamentoModelo: '',
                    enviaPorEmail: false,
                    validadeInicial: null,
                    email: '',
                    paciente: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    medico: {
                        id: null
                    },
                    guia: {
                        id: null
                    },
                    exames: [],
                },
                convenioId: null,  
                particular: false,                              
                exameBloqueado: false,
                data: [],
                gruposExames: [],
                procurarExamePor: 'Apelido',
                salvandoDados: false,
                totalExames: 0,
                isFetching: false,
                grupos: false,
                autocomplete: null
            };
        },
        components: {
            titlebar,  
            historicoAlteracoes,
            searchIdName          
        },
        created() {
            if (this.$route.params.id) {
                this.salvandoDados = true;
                this.$http.get('/api/atendimento/orcamento?id=' + this.$route.params.id)
                    .then(res => {      
                        this.convenioId = res.data.convenio.id;                  
                        this.particular = res.data.convenio.particular;
                        this.model = res.data;   
                        this.model.emissao = new Date(this.model.emissao);
                        this.model.validade = new Date(this.model.validade);
                        this.model.validadeInicial = this.model.validade;
                        this.model.desconto = res.data.desconto >= 0 ? res.data.desconto : 0; 
                        this.model.desconto = this.formatPrice(this.model.desconto)       
                        this.recalcularTotal();
                        this.salvandoDados=false;
                    })

            }else{
                
                this.model.emissao =  new Date();
                this.model.validade = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
   
            }

            document.addEventListener('visibilitychange', this.verificaRetornar, false);                       
            
            this.$http.get('/api/search/gruposexames')
                .then(res => {
                    this.gruposExames = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ORCAMENTOCADASTRO.ATENDIMENTO'),
                    this.$t('ORCAMENTOCADASTRO.ORCAMENTO'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },          
            ...mapState([
                'unidadeId',
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            totalExamesDesconto(){
                if(this.model.desconto != null && this.model.desconto != '' && this.model.desconto != undefined) {                    
                    if((this.model.desconto + "").indexOf("%") != -1) {
                        return (parseFloat(this.totalExames) - 
                            (parseFloat(this.totalExames) * 
                            (parseFloat((this.model.desconto + "").replace("%", "").replace(",", ".")) / 100) ) );
                    } else {
                        return (parseFloat(this.totalExames) - parseFloat((this.model.desconto + "").replace(",", ".")));
                    }
                }
                
                return this.totalExames;
            }
        },
        methods: {
            formatPrice(value) {
                const val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            verificaRetornar() {   
                if(localStorage.retornarPaciente > 0){                    
                    this.model.paciente.id = localStorage.retornarPaciente;                    
                    localStorage.retornarPaciente = null;
                } 
                if(localStorage.retornarMedico > 0){                    
                    this.model.medico.id = localStorage.retornarMedico;                    
                    localStorage.retornarMedico = null;
                }                                
            }, 
                       
            onSubmit() {
                if(this.model.paciente.id == null || this.model.paciente.id == '' || this.model.paciente.id == 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('ORCAMENTOCADASTRO.PACIENTEOBRIGATORIO'),
                        message: this.$t('ORCAMENTOCADASTRO.INFORMARPACIENTE'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }
           
                if(this.model.desconto != null && this.model.desconto != '' && this.model.desconto != undefined) { 
                    if((this.model.desconto + "").indexOf("%") != -1) {
                        this.model.desconto = parseFloat(this.totalExames) - parseFloat(this.totalExamesDesconto)                        
                    }
                    else {
                        this.model.desconto = (this.model.desconto + "").replace(",", ".");
                    }
                }

                const newItem = Object.assign({}, this.model);  
            
                this.salvandoDados = true;
                this.$http.post('/api/atendimento/orcamento', newItem)
                    .then(res => res.data)
                    .then(data => {
                        // this.$router.push({ name: 'atendimento' })
                        this.$router.back()
                    })
                    .catch(err => {
                        if(err.status == 404){
                            if (err.body?.indexOf('desativado') > 0) {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                });
                            } else {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('ORCAMENTOCADASTRO.ERROSALVAR') + err.body,
                                    type: 'is-danger',
                                    queue: false
                                })

                                Object.keys(err.body.errors).forEach(x => {
                                    this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                                })

                            }
                        }else {
                            const primeiralinha = err.body.substring(0, err.body.indexOf('\n'))
                            console.log('ERRO:' + err.body)

                            this.$buefy.toast.open({
                                duration: 10000,
                                message: primeiralinha,
                                type: 'is-danger',
                                queue: false
                            })
                        }
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    })
            },
            getAsyncExame: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetching = true
                this.$http.get(`/Search/OrcamentoExame?nome=${name}&tipo=${this.procurarExamePor}&convenioId=${this.model.convenio.id}`)
                    .then(({ data }) => {
                        this.data = []
                        data.forEach((item) => this.data.push(item))

                        this.exameBloqueado = data.filter(item => item.bloqueado)
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            enviarEmail(){
                this.$buefy.dialog.prompt({
                    title: this.$t('ORCAMENTOCADASTRO.TITULOENVIAREMAIL'),
                    message: this.$t('ORCAMENTOCADASTRO.TITULOENVIAREMAIL'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: this.$t('ORCAMENTOCADASTRO.EMAIL')
                    }, 
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('ORCAMENTOCADASTRO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.model.email = res;
                        this.model.enviaPorEmail = true;
                        this.onSubmit();
                    }
                })                
            },
            incluirGrupo(grupo){
                grupo.grupoItens.forEach((item) => {                    
                    this.$http.get(`/api/Search/OrcamentoExameId?id=${item.exame.id}&convenioId=${this.model.convenio.id}`)
                        .then(({ data }) => {                                                        
                            data.forEach((item) => item.bloqueado ? null : this.model.exames.push(item));
                            this.recalcularTotal();
                        })
                });
            },
            modeloOrcamento(modelo) {
                let url = `/api/atendimento/ImprimeOrcamento?id=${this.model.id}`;
                if(modelo != null && modelo != '' && modelo != undefined){
                    url += "&modelo=" + modelo;
                }
                
                window.open(url, '_blank');
            },
            removerExame(exame){      
                if(this.model.examesRemovidos == null){
                    this.model.examesRemovidos = [];
                }
                this.model.examesRemovidos.push(this.model.exames?.filter((i) => i.exameId == exame.exameId)[0]);
                this.model.exames = this.model.exames?.filter((i) => i.exameId != exame.exameId);

                if(exame.complementoDoExame != null && exame.complementoDoExame > 0) {
                    let count = 0;
                    //remove exame pai
                    this.model.exames.filter(exames => exames.exameId == exame.complementoDoExame).forEach(exame => {
                        if(count == 0 && exame.examePerfil){
                            this.model.examesRemovidos.push(this.model.exames?.filter((i) => i.exameId == exame.exameId)[0]);

                            const exameIndex = this.getExameIndex(exame.exameId);
                            this.model.exames.splice(exameIndex, 1);
                            count += 1;
    
                        }
                    });
    
                    //remove vinculo do exame filho com o pai(removido anteriormente)
                    this.model.exames.filter(exames => exames.complementoDoExame == exame.complementoDoExame).forEach( exame => {
                        exame.complementoDoExame = 0;                        
                    });
                }
                
                this.loadPrecos();
                this.recalcularTotal();
            },
            getExameIndex(exameId) {
                let exameIndex = 0;

                const result = this.model.exames.filter((exa) => {
                    return exa.exameId == exameId;
                });
                    
                for(const exame of result){
                    exameIndex = this.model.exames.indexOf(exame);    
                }

                return exameIndex;
            },
            recalcularTotal() {
                this.totalExames = 0;
                this.model.exames.forEach((v) => {
                    this.totalExames += parseFloat(v.examePreco?.toString());
                });
                this.totalExames = this.totalExames.toFixed(2);                
            },
            selectItem(option) {
                if(option?.bloqueado){
                    return;
                }
                
                if(option != null){
                    
                    if(this.model.exames.filter(x => x.exameId == option.exameId).length > 0 ){                                
                                this.$buefy.dialog.confirm({
                                        title: this.$t('ORCAMENTOCADASTRO.EXAMETITULO'),
                                        message: this.$t('ORCAMENTOCADASTRO.MENSAGEMCONFIRMACAO') + option.exameApelido + '</b> ?',
                                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                        type: 'is-info',
                                        hasIcon: true,
                                        cancelText: this.$t('ORCAMENTOCADASTRO.CANCELAR'),  
                                        onConfirm: () => {
                                            this.adicionarExame(option);
                                        },                                                              
                                        onCancel: function () {
                                            return;
                                        },                                        
                                    });  

                    }else{
                        this.adicionarExame(option);
                    }
                    
                }                
                

            },
            async loadPrecos() 
            {
                const examesPreco = {
                    exames: this.model.exames.map(x => {
                        return{
                            exameId: x.exameId,
                            preco: 0.0,
                            setorId: 0,
                            itemExame: null,
                            urgente: false
                        }
                    }),
                    convenioId: this.model.convenio.id
                }

                const res = await this.$http.post('/api/manutencao/ExamesCalculaPrecoParticular', examesPreco)
                .then(res => res.data)
                .then(data => {                        
                    this.model.exames.forEach(e => {
                        
                        const exame = data.exames.filter(x => x.exameId == e.exameId)[0];

                        if(e.complementoDoExame){
                            const examePai = this.model.exames.filter(c => c.exameId == e.complementoDoExame);
                            
                            e.examePreco = examePai[0]?.examePerfil
                                ? 0
                                : exame.preco;
                        }
                        else {
                            e.examePreco = exame.preco;
                        }
                    });

                    this.recalcularTotal();
                    this.$forceUpdate();
                })

            },
            adicionarExame(option){
                this.model.exames.push({
                    bloqueado: option.bloqueado,
                    exameApelido: option.exameApelido,
                    exameCodigo: option.exameCodigo,
                    exameId: option.exameId,
                    exameNome: option.exameNome,
                    examePreco: option.preco,
                    recipienteId: option.recipienteId,
                    materialNome: option.materialNome,
                    conservanteNome: option.conservanteNome,
                    motivoBloqueado: option.motivoBloqueado,
                    examePerfil: option.examePerfil,
                    id: 0
                });                
                    
                this.loadExamesComplementares(option.exameId);

                setTimeout(() => this.autocomplete = '', 10);
                this.recalcularTotal();
            },
            loadExamesComplementares(complementoId){
                this.$http.get('/api/atendimento/ExamesComplementares?exameId=' + complementoId + '&convenioId=' + this.model.convenio.id)
                .then(res => res.data)
                .then((exames) => {
                    if(exames != null && exames.length > 0){          
                        exames.forEach(exameComplementar => {    
                            const idComplementar = complementoId ? exameComplementar.id : exameComplementar.exameId;

                            this.model.exames.push({
                                exameId: idComplementar,
                                exameApelido: exameComplementar.apelido,
                                exameNome: exameComplementar.nome,
                                recipienteId: exameComplementar.recipienteId,
                                examePerfil: exameComplementar.exameDePerfil,
                                complementoDoExame: complementoId,
                            })
                        });  
                    }

                    this.loadPrecos();
                })
            },
            escolheuConvenio(val){
                if(val.data.desconto && val.data.desconto > 0 ){
                    this.model.desconto = val.data.desconto + "%";
                }

                if(val.data.id != this.convenioId && !val.data.convenioTipoParticular) {
                    this.model.exames.forEach((exame) => {
                        this.$http.get(`/api/Search/OrcamentoExameId?id=${exame.exameId}&convenioId=${this.model.convenio.id}`)
                            .then(({ data }) => {
                                data.forEach((item) => {
                                    if(item.exameId == exame.exameId)
                                    {
                                        exame.examePreco = item.examePreco;
                                    }
                                });
                                this.recalcularTotal();
                            })
                    });

                    this.convenioId = val.data.id;
                }

                if(val.data.id != this.convenioId && val.data.convenioTipoParticular){
                    this.convenioId = val.data.id;
                    this.loadPrecos();
                }

            },
            criarGuia(){
                this.salvandoDados = true;
                this.$http.get(`/api/atendimento/OrcamentoParaGuia?orcamentoId=${this.model.id}&medicoId=${this.model.medico.id}&unidadeId=${this.unidadeId}&localDeOrigemId=${this.model.localOrigemId}`)
                    .then(res => res.data)
                    .then(data => {
                        if(data.sucesso){
                            this.$router.push({ name: 'guia', params: { id: data.id }});
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('ORCAMENTOCADASTRO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    })
            }
        }
    }
</script>
<template>
    <section>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('guia-bancada')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUANALISE.MAPATRABALHO')}}
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'emissao-bancada' }" class="button is-primary is-fullwidth">
                            {{$t('MENUANALISE.EMISSAO')}}
                        </router-link>
                    </div>
                    <p class="panel-block">
                        {{$t('MENUANALISE.PROGRESSOPOR')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'progresso-bancada' }" class="button is-info is-fullwidth">
                            {{$t('MENUANALISE.BANCADA')}}
                        </router-link>
                        <router-link :to="{ name: 'progresso-setor' }" class="button is-light is-fullwidth">
                            {{$t('MENUANALISE.SETOR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !guiasCarregadas" class="panel">
                    <p class="panel-heading has-background-warning">
                        {{$t('MENUANALISE.GUIASURGENTESINDICADORES')}}
                    </p>
                    <p class="panel-block">
                        <button class="button is-light is-fullwidth" @click="carregarGuiasPendentes">
                            {{$t('MENUANALISE.LISTARPENDENCIAS')}}
                        </button>
                    </p>
                </div>

                <div v-if="guiasUrgentes.length > 0" class="panel">
                    <p class="panel-heading has-background-warning">
                        {{$t('MENUANALISE.GUIASURGENTESLOCAL')}}

                    </p>
                    <b-collapse class="card"
                                animation="slide"
                                v-for="(guias, local, index) of guiasUrgentesByLocal"
                                :key="index"
                                :open="isLocalUrgenteOpen == index"
                                @open="isLocalUrgenteOpen = index">
                        <div slot="trigger"
                             slot-scope="props"
                             class="card-header has-background-light"
                             role="button">
                            <p class="card-header-title">
                                {{ local }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                            </a>
                        </div>
                        <div class="panel">
                            <router-link v-for="item in guias" v-bind:key="item.id"
                                         :to="{ name: 'guia', params: { id: item.id }}"
                                         class="panel-block is-justified-between">
                                <span class="tag is-pulled-right">
                                    {{item.id}}
                                </span>
                                {{item.pacienteNome}}
                            </router-link>
                        </div>
                    </b-collapse>
                </div>
                <div v-if="guiasIndicadores.length > 0" class="panel">
                    <p class="panel-heading has-background-warning">
                        {{$t('MENUANALISE.GUIASINDICADORES')}}
                    </p>
                    <router-link v-for="(item, index) of guiasIndicadores"
                            :key="index"
                            :to="{ name: 'guia', params: { id: item.guiaId }}" class="panel-block is-justified-between">
                            <div class="columns is-multiline">
                                <div class="column">
                                    <span class="tag">
                                        {{item.guiaId}}
                                    </span>
                                </div>
                                <div class="column is-8">
                                    <span>
                                        {{item.pacienteNome}}
                                    </span>
                                </div>
                                <div class="column is-12">
                                    <guiaIndicadores :guia="item"></guiaIndicadores>
                                </div>
                            </div>
                    </router-link>                    
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('guia-bancada')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUANALISE.ACOMPANHAMENTO')}}
                    </p>
                    <div class="panel-block">
                        <periodo :dataInicial.sync="acompanhamentoDatas[0]"
                                 :dataFinal.sync="acompanhamentoDatas[1]"
                                 @changed="atualizaDadosAcompanhamento" />
                    </div>
                    <p class="panel-block">
                        {{$t('MENUANALISE.EXAMES')}}
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'exames-bloqueados', 
                                            query: { unidadeId: unidadeId,
                                                     dataInicial: this.$moment(acompanhamentoDatas[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), 
                                                     dataFinal: this.$moment(acompanhamentoDatas[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                                                    } 
                                          }" 
                                    class="button is-dark is-fullwidth" :disabled="examesBloqueados != 0 ? false : true">
                            <b style="margin-right:2px;">{{ examesBloqueados }}</b>
                            <span>{{ textoExamesBloqueados }}</span>
                        </router-link>
                        <router-link :to="{ name: 'exames-entregas' }" class="button is-info is-fullwidth" :title="$t('MENUANALISE.INFOENTREGAS')">
                            <span>{{$t('MENUANALISE.ENTREGAS')}}</span>
                        </router-link>
                    </div>
                    <p class="panel-block">
                        {{$t('MENUANALISE.AMOSTRAS')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'amostraIncidencia',
                                            query: { unidadeId: unidadeId,
                                                     dataInicial: this.$moment(acompanhamentoDatas[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                                     dataFinal: this.$moment(acompanhamentoDatas[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                                                    }
                                          }"
                                    class="button is-light is-fullwidth" :disabled="amostrasIncidencia != 0 ? false : true">
                            <b style="margin-right:2px;">{{ amostrasIncidencia }}</b>
                            <span>{{ textoAmostrasIncidencia }}</span>
                        </router-link>
                    </div>
                    <p class="panel-block">
                        {{$t('MENUANALISE.ESTUDOS')}}
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'estudos',
                                            query: { semVinculo: true,
                                                     dataInicial: this.$moment(acompanhamentoDatas[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                                     dataFinal: this.$moment(acompanhamentoDatas[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                                                    }
                                          }"
                                    class="button is-light is-fullwidth" :disabled="estudosSemVinculo != 0 ? false : true">
                            <b style="margin-right:2px;">{{ estudosSemVinculo }}</b>
                            <span>{{ textoEstudosSemVinculo }}</span>
                        </router-link>
                        <router-link :to="{ name: 'estudos', query: { unidadeId: unidadeId } }" class="button is-fullwidth">
                            <span>{{$t('MENUANALISE.TODOS')}}</span>
                        </router-link>
                    </div>                
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('resultado-editar')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUANALISE.DIGITACAORESULTADOS')}}
                    </p>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'solicitacao' }" @click="digitarResultadoFiltro = 'solicitacao';atualizaPendenciasDigitacao()">{{$t('MENUANALISE.SOLICITACAO')}}</a>
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'coleta' }" @click="digitarResultadoFiltro = 'coleta';atualizaPendenciasDigitacao()">{{$t('MENUANALISE.COLETA')}}</a>
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'entrega' }" @click="digitarResultadoFiltro = 'entrega';atualizaPendenciasDigitacao()">{{$t('MENUANALISE.ENTREGA')}}</a>
                    </p>
                    <div class="panel-block">
                        <periodo :dataInicial.sync="digitarResultadoDatas[0]" 
                                 :dataFinal.sync="digitarResultadoDatas[1]" 
                                 @changed="atualizaPendenciasDigitacao" />
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': digitarResultadoCampo == 'bancadaNumero' }" @click="digitarResultadoCampo = 'bancadaNumero'">{{$t('MENUANALISE.BANCADASETOR')}}</a>
                        <a :class="{ 'is-active': digitarResultadoCampo == 'guiaId' }" @click="digitarResultadoCampo = 'guiaId'">{{$t('MENUANALISE.GUIA')}}</a>
                        <a :class="{ 'is-active': digitarResultadoCampo == 'codigoBarras' }" @click="digitarResultadoCampo = 'codigoBarras'">{{$t('MENUANALISE.AMOSTRA')}}</a>
                        <a :class="{ 'is-active': digitarResultadoCampo == 'exameApelido' }" @click="digitarResultadoCampo = 'exameApelido'">{{$t('MENUANALISE.EXAME')}}</a>
                    </p>
                   <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onSubmitDigitarResultados)" >
                            <div class="panel-block" v-show="digitarResultadoCampo !== 'exameApelido'">
                                <b-input
                                    :placeholder="$t('MENUANALISE.PROCURARPOR')+ ((digitarResultadoCampo =='bancadaNumero') ? $t('MENUANALISE.BANCADA') : (digitarResultadoCampo  == 'codigoBarras' ? $t('MENUANALISE.CODIGOBARRAS') : $t('MENUANALISE.GUIA') ))"
                                    type="number"
                                    class="fullwidth"
                                    @keydown.native.enter.prevent="handleSubmit(onSubmitDigitarResultados)"
                                    v-model="digitarResultadoCampoValorBancada"
                                    icon="magnify">
                                </b-input>                                
                            </div>
                            <b-autocomplete field="setor"
                                class="panel-block"
                                v-if="(digitarResultadoCampo == 'bancadaNumero')"
                                v-model="digitarResultadoCampoValorSetor"
                                :placeholder="$t('MENUANALISE.PROCURARSETOR')"
                                :data="listaSetorAutocomplete"
                                :loading="isFetching"
                                @keydown.native.enter.prevent="handleSubmit(onSubmitDigitarResultados)"
                                @typing="procurarSetorAutocomplete"
                                @select="option => {selectedDigitacao = option; handleSubmit(onSubmitDigitarResultados)}"
                                :select-on-click-outside="selectOutside"
                                keep-first
                                icon="magnify"
                                >
                                <template slot-scope="props">
                                    <div class="media">
                                        <div class="media-left">
                                            <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                        </div>
                                        <div class="media-content">
                                            {{ props.option.nome }}
                                        </div>
                                    </div>
                                </template>
                            </b-autocomplete>
                            <b-autocomplete field="exame"
                                class="panel-block"
                                v-if="(digitarResultadoCampo === 'exameApelido')"
                                v-model="digitarResultadoCampoValorBancada"
                                :placeholder="$t('MENUANALISE.EXAME')"
                                :data="listaExameAutocomplete"
                                :loading="isFetching"
                                @keydown.native.enter.prevent="handleSubmit(onSubmitDigitarResultados)"
                                @typing="procurarExameAutocomplete"
                                @select="option => {selectedDigitacao = option; handleSubmit(onSubmitDigitarResultados)}"
                                :select-on-click-outside="selectOutside"
                                keep-first
                                icon="magnify"
                                >
                                <template slot-scope="props">
                                    <div class="media">
                                        <div class="media-left">
                                            <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                        </div>
                                        <div class="media-content">
                                            {{ props.option.apelido }}
                                        </div>
                                        <div class="media-content">
                                            {{ props.option.nome }}
                                        </div>
                                    </div>
                                </template>
                            </b-autocomplete>
                            <input type="hidden" v-model="unidadeId" name="unidadeId" />
                        </form>
                    </ValidationObserver>
                    <p class="panel-block">
                        {{$t('MENUANALISE.LISTARPENDENCIASDIGITACAO')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'local' }" @click="pendenciasDigitacaoPorLocal">
                            {{$t('MENUANALISE.LOCAL')}}
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'setor' }" @click="pendenciasDigitacaoPorSetor">
                            {{$t('MENUANALISE.SETOR')}}
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'convenio' }" @click="pendenciasDigitacaoPorConvenio">
                            {{$t('SISTEMA.CONVENIO')}}
                        </a>
                    </div>
                    <div v-for="(item, index) in pendenciasDigitacao" :key="index">
                        <a :href="item.href" class="panel-block is-justified-between">
                            <span>{{item.nome}}</span>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                    <p class="panel-heading" v-if="pendenciasDigitacaoPor != '' && pendenciasDigitacao.length == 0">
                        <small>{{$t('MENUANALISE.NENHUMAPENDENCIAFILTROSELECIONADO')}}</small>
                    </p>

                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('resultado-assinar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUANALISE.ASSINATURARESULTADOS')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <b-radio v-model="assinaturaTipo"
                                 size="is-small"
                                 native-value="/Guia/Assinatura"
                                 @change.native="salvarAssinaturaTipo=='/Guia/Assinatura'; atualizaPendenciasAssinatura(); ">                               
                            <span>{{$t('MENUANALISE.EMLISTA')}}</span>
                        </b-radio>

                        <b-radio v-model="assinaturaTipo"
                                 size="is-small"
                                 native-value="/Analise/AssinaturaGuia"
                                 @change.native="salvarAssinaturaTipo=='/Analise/Assinatura'; atualizaPendenciasAssinatura();" >                                                                
                            <span>{{$t('MENUANALISE.PORGUIA')}}</span>
                        </b-radio>

                        <b-checkbox v-model="this.somenteValidados"
                                    size="is-small"
                                    type="is-danger"
                                    @click.prevent.native="atualizaSomenteValidados()">
                            {{$t('MENUANALISE.VALIDADOS')}}
                        </b-checkbox>
                        
                        <b-checkbox v-model="this.somenteNaoValidados"
                                    size="is-small"
                                    type="is-danger"
                                    @click.prevent.native="atualizaSomenteNaoValidados()">
                            {{$t('MENUANALISE.NAOVALIDADOS')}}
                        </b-checkbox>

                        <b-checkbox v-model="somenteUrgentes"
                                    size="is-small"
                                    type="is-danger"
                                    @change.native="atualizaPendenciasAssinatura">
                            {{$t('MENUANALISE.SOMENTEURGENTES')}}
                        </b-checkbox>
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'solicitacao' }" @click="assinarResultadoFiltro = 'solicitacao'; atualizaPendenciasAssinatura()">{{$t('MENUANALISE.SOLICITACAO')}}</a>
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'coleta' }" @click="assinarResultadoFiltro = 'coleta'; atualizaPendenciasAssinatura()">{{$t('MENUANALISE.COLETA')}}</a>
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'entrega' }" @click="assinarResultadoFiltro = 'entrega'; atualizaPendenciasAssinatura()">{{$t('MENUANALISE.ENTREGA')}}</a>
                    </p>
                    <div class="panel-block">
                        <periodo :dataInicial.sync="assinarResultadoDatas[0]"
                                 :dataFinal.sync="assinarResultadoDatas[1]"
                                 @changed="atualizaPendenciasAssinatura" />
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': assinarResultadoCampo == 'bancadaNumero' }" @click="assinarResultadoCampo = 'bancadaNumero'">{{$t('MENUANALISE.BANCADASETOR')}}</a>
                        <a :class="{ 'is-active': assinarResultadoCampo == 'guiaId' }" @click="assinarResultadoCampo = 'guiaId'">{{$t('MENUANALISE.GUIA')}}</a>
                        <a :class="{ 'is-active': assinarResultadoCampo == 'codigoBarras' }" @click="assinarResultadoCampo = 'codigoBarras'">{{$t('MENUANALISE.AMOSTRA')}}</a>
                        <a :class="{ 'is-active': assinarResultadoCampo == 'exameApelido' }" @click="assinarResultadoCampo = 'exameApelido'">{{$t('MENUANALISE.EXAME')}}</a>
                    </p>

                    <form @submit.prevent="onSubmitAssinarResultados" method="get">
                        <div class="panel-block" v-show="assinarResultadoCampo !== 'exameApelido'">
                            <b-input :placeholder="$t('MENUANALISE.PROCURARPOR')  + ((assinarResultadoCampo == 'bancadaNumero') ? $t('MENUANALISE.BANCADA') : (assinarResultadoCampo  == 'codigoBarras' ? $t('MENUANALISE.CODIGOBARRAS') : $t('MENUANALISE.GUIA') ))"
                                type="number"
                                v-model="assinarCampoValorBancada"
                                @keydown.native.enter.prevent="onSubmitAssinarResultados()"
                                icon="magnify">
                            </b-input>
                        </div> 
                        <b-autocomplete field="setor"
                                class="panel-block"
                                v-if="(assinarResultadoCampo == 'bancadaNumero')"
                                v-model="assinarCampoValorSetor"
                                placeholder="Procurar por Setor"
                                :data="listaSetorAutocomplete"
                                :loading="isFetching"
                                @keydown.native.enter.prevent="onSubmitAssinarResultados()"
                                @typing="procurarSetorAutocomplete"
                                @select="option => {selectedAssinatura = option; this.onSubmitAssinarResultados()}"
                                :select-on-click-outside="selectOutside"
                                keep-first
                                icon="magnify"
                                    >
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>
                                </div>
                            </template>
                        </b-autocomplete>
                        <b-autocomplete field="exame"
                                class="panel-block"
                                v-if="(assinarResultadoCampo === 'exameApelido')"
                                v-model="assinarCampoValorBancada"
                                :placeholder="$t('MENUANALISE.EXAME')"
                                :data="listaExameAutocomplete"
                                :loading="isFetching"
                                @keydown.native.enter.prevent="onSubmitAssinarResultados()"
                                @typing="procurarExameAutocomplete"
                                @select="option => {selectedAssinatura = option; this.onSubmitAssinarResultados()}"
                                :select-on-click-outside="selectOutside"
                                keep-first
                                icon="magnify"
                                >
                                <template slot-scope="props">
                                    <div class="media">
                                        <div class="media-left">
                                            <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                        </div>
                                        <div class="media-content">
                                            {{ props.option.apelido }}
                                        </div>
                                        <div class="media-content">
                                            {{ props.option.nome }}
                                        </div>
                                    </div>
                                </template>
                            </b-autocomplete>
                        <input type="hidden" v-model="unidadeId" name="unidadeId" />
                    </form>

                    <p class="panel-block">
                        {{$t('MENUANALISE.LISTARPENDENCIASASSINATURA')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'local' }" @click="pendenciasAssinaturaPorLocal">
                            {{$t('MENUANALISE.LOCAL')}}
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'setor' }" @click="pendenciasAssinaturaPorSetor">
                            {{$t('MENUANALISE.SETOR')}}
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'convenio' }" @click="pendenciasAssinaturaPorConvenio">
                           {{$t('SISTEMA.CONVENIO')}}
                        </a>
                    </div>
                    <div v-for="(item, index) in pendenciasAssinatura" :key="index">
                        <a :href="item.href" class="panel-block is-justified-between">
                            <span>{{item.nome}}</span>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                    <p class="panel-heading" v-if="pendenciasAssinaturaPor != '' && pendenciasAssinatura.length == 0">
                        <small>{{$t('MENUANALISE.NENHUMAPENDENCIAFILTROSELECIONADO')}}</small>
                    </p>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('digitacao-lotes')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUANALISE.DIGITACAORESULTADOSLOTE')}}
                    </p>
                    <div class="panel-block">
                        <a :href="'/Analise/DigitarPorLotes'" class="button is-link is-outlined is-fullwidth">
                            {{$t('MENUANALISE.DIGITACAOLOTE')}}
                        </a>
                    </div>
                </div>

                <div v-if="isInRole('resultado-visualizar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUANALISE.EMISSAORESULTADOSLOTE')}}
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'lote-resultados' }" class="button is-primary is-outlined is-fullwidth">
                            {{$t('MENUANALISE.EMITIRLOTE')}}
                        </router-link>
                    </div>
                    <form @submit.prevent="abrirLote" method="get" class="panel-block">
                        <b-input v-model="lote" name="lote" :placeholder="$t('MENUANALISE.BUSCARLOTE')" icon="magnify" required/>
                    </form>
                </div>

                <div v-if="isInRole('soroteca')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUANALISE.SOROTECA')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'soroteca-armazenar' }" class="button is-info is-fullwidth">
                            Armazenar amostras
                        </router-link>
                        <router-link :to="{ name: 'soroteca-localizar' }" class="button is-light is-fullwidth">
                            Soroteca
                        </router-link>                        
                                           
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import guiaIndicadores from '@/components/atendimento/guiaIndicadores.vue'
    import periodo from '@/components/periodo.vue'
    import debounce from 'lodash/debounce'


    export default {
        data() {
            return {
                amostrasIncidencia: 0,
                examesBloqueados: 0,
                estudosSemVinculo: 0,
                guiasIndicadores: [],
                guiasUrgentes: [],
                guiasCarregadas: false,

                isLocalUrgenteOpen: -1,
                isLocalIndicadoresOpen: -1,

                digitarResultadoFiltro: localStorage.digitarResultadoFiltro ?? 'solicitacao',
                digitarResultadoCampo: 'guiaId',
                digitarResultadoCampoValorSetor: null,
                digitarResultadoCampoValorBancada: null,
                selectedDigitacao: null,
                listaSetorAutocomplete: [],
                listaExameAutocomplete: [],
                isFetching: false,

                digitarResultadoDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                pendenciasDigitacao: [],
                pendenciasDigitacaoPor: '',

                assinarResultadoFiltro: localStorage.assinarResultadoFiltro ?? 'solicitacao',
                assinarResultadoCampo: 'guiaId',
                assinarCampoValorSetor: null,
                assinarCampoValorBancada: null,
                selectedAssinatura: null,
                assinarResultadoDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                pendenciasAssinatura: [],
                pendenciasAssinaturaPor: '',
                assinaturaTipo: localStorage.assinaturaTipo ?? '/Guia/Assinatura',
                somenteUrgentes: (localStorage.somenteUrgentes == "true"),
                somenteValidados: localStorage.somenteValidados ?? false,
                somenteNaoValidados: localStorage.somenteNaoValidados ?? false,
                acompanhamentoDatas: [
                    this.$moment().subtract(30, 'days').startOf('day').toDate(),
                    this.$moment().endOf('day').toDate()
                ],
            }
        },
        components: {
            guiaIndicadores,
            periodo,
        },
        computed: {
            textoExamesBloqueados() {
                return this.examesBloqueados > 1
                    ? this.$i18n.t('MENUANALISE.EXAMESBLOQUEADOS')
                    : this.$i18n.t('MENUANALISE.EXAMEBLOQUEADO')
            },
            textoAmostrasIncidencia() {
                return this.amostrasIncidencia > 1
                    ? this.$i18n.t('MENUANALISE.AMOSTRASINCIDENCIAS')
                    : this.$i18n.t('MENUANALISE.AMOSTRAINCIDENCIA')
            },
            textoEstudosSemVinculo() {
                return this.estudosSemVinculo > 1
                    ? this.$i18n.t('MENUANALISE.ESTUDOSVINCULO')
                    : this.$i18n.t('MENUANALISE.ESTUDOVINCULO')
            },
            ...mapState([
                'unidadeId',
                'config'
            ]),
            guiasUrgentesByLocal() {
                return this.guiasUrgentes.reduce((acc, guia) => {
                    (acc[guia.localDeOrigemNome] = acc[guia.localDeOrigemNome] || []).push(guia);
                    return acc;
                }, {})
            },
            periodoDigitacaoReq() {
                return this.digitarResultadoDatas.length == 2
                    ? '&filtrarPor=' + this.digitarResultadoFiltro +
                        '&dataInicial=' + this.$moment(this.digitarResultadoDatas[0]).format() +
                        '&datafinal=' + this.$moment(this.digitarResultadoDatas[1]).format()
                    : ''
            },
            periodoAssinaturaReq() {
                return (this.somenteUrgentes
                    ? '&urgentes=true'
                    : '') +
                    (this.somenteValidados
                    ? `&validados=${this.somenteValidados}`
                    : '') +
                    (this.somenteNaoValidados
                    ? `&naoValidados=${this.somenteNaoValidados}`
                    : '') +
                    (this.assinarResultadoDatas.length == 2
                        ? '&filtrarPor=' + this.assinarResultadoFiltro +
                            '&dataInicial=' + this.$moment(this.assinarResultadoDatas[0]).format() +
                            '&datafinal=' + this.$moment(this.assinarResultadoDatas[1]).format()
                        : '')
            },
            periodoAcompanhamentoReq() {
                return this.acompanhamentoDatas.length == 2
                    ? '&dataInicial=' + this.$moment(this.acompanhamentoDatas[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                      '&datafinal=' + this.$moment(this.acompanhamentoDatas[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                    : ''
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            atualizaSomenteValidados(){
                this.somenteValidados = !this.somenteValidados;
                localStorage.somenteValidados = this.somenteValidados;
                this.somenteNaoValidados = false;
            },
            atualizaSomenteNaoValidados(){
                this.somenteNaoValidados = !this.somenteNaoValidados;
                localStorage.somenteNaoValidados = this.somenteNaoValidados;
                this.somenteValidados = false;
            },
            pendenciasDigitacaoPorLocal() {
                this.pendenciasDigitacaoPor = 'local'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=local&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.localNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&localId=' + x.localId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            pendenciasDigitacaoPorSetor() {
                this.pendenciasDigitacaoPor = 'setor'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=setor&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.setorNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&setorId=' + x.setorId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            pendenciasDigitacaoPorConvenio() {
                this.pendenciasDigitacaoPor = 'convenio'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=convenio&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.convenioNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&convenioId=' + x.convenioId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            atualizaPendenciasDigitacao() {
                localStorage.digitarResultadoFiltro = this.digitarResultadoFiltro;

                if (this.pendenciasDigitacaoPor == 'local') {
                    this.pendenciasDigitacaoPorLocal()
                } else if (this.pendenciasDigitacaoPor == 'setor') {
                    this.pendenciasDigitacaoPorSetor()
                } else if (this.pendenciasDigitacaoPor == 'convenio') {
                    this.pendenciasDigitacaoPorConvenio()
                }
            },
            
            pendenciasAssinaturaPorLocal() {
                this.pendenciasAssinaturaPor = 'local';
                                
                let href = '';
                if(this.assinaturaTipo == "/Guia/Assinatura"){
                    href = "Guia/Assinatura?";
                }else{
                    href = "Analise/AssinaturaGuia?";
                }

                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=local&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.localNome,
                                quantidade: x.quantidade,
                                href: href +
                                    'unidadeId=' + this.unidadeId +
                                    '&localId=' + x.localId +
                                    this.periodoAssinaturaReq
                            }
                        })
                    })
            },
            pendenciasAssinaturaPorSetor() {
                this.pendenciasAssinaturaPor = 'setor';

                let href = '';
                if(this.assinaturaTipo == "/Guia/Assinatura"){
                    href = "Guia/AssinaturaPorSetor?";
                }else{
                    href = "Analise/AssinaturaGuia?";
                }

                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=setor&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.setorNome,
                                quantidade: x.quantidade,
                                href: href +
                                    'unidadeId=' + this.unidadeId +
                                    '&setorId=' + x.setorId +
                                    this.periodoAssinaturaReq
                                    
                            }
                        })
                    })
            },
            
            pendenciasAssinaturaPorConvenio() {
                this.pendenciasAssinaturaPor = 'convenio';

                let href = '';
                if(this.assinaturaTipo == "/Guia/Assinatura"){
                    href = "Guia/AssinaturaPorConvenio?";
                }else{
                    href = "Analise/AssinaturaGuia?";
                }

                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=convenio&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.convenioNome,
                                quantidade: x.quantidade,
                                href: href +
                                    'unidadeId=' + this.unidadeId +
                                    '&convenioId=' + x.convenioId +
                                    this.periodoAssinaturaReq
                            }
                        })
                    })
            },
            atualizaPendenciasAssinatura() {                
                localStorage.assinarResultadoFiltro = this.assinarResultadoFiltro;
                localStorage.somenteUrgentes = this.somenteUrgentes;
                localStorage.somenteValidados = this.somenteValidados;
                localStorage.somenteNaoValidados = this.somenteNaoValidados;

                if (this.pendenciasAssinaturaPor == 'local') {
                    this.pendenciasAssinaturaPorLocal()
                } else if (this.pendenciasAssinaturaPor == 'setor') {
                    this.pendenciasAssinaturaPorSetor()
                } else if (this.pendenciasAssinaturaPor == 'convenio') {
                    this.pendenciasAssinaturaPorConvenio()
                }
            },            
            salvarAssinaturaTipo() {
                localStorage.assinaturaTipo = this.assinaturaTipo;
            },

            carregarGuiasPendentes() {
                this.guiasCarregadas = true;
                this.$http.get('api/Dashboard/GuiasIndicadoresSemResultado?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guiasIndicadores = res.data;
                    });

                this.$http.get('api/Dashboard/GuiasUrgentesSemResultado?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guiasUrgentes = res.data
                    });
            },
            onSubmitDigitarResultados(){
                let url = "/Analise/DigitarResultadosPor?unidadeId=" + this.unidadeId + this.periodoDigitacaoReq;

                if(this.digitarResultadoCampo == "bancadaNumero"){

                    if(this.digitarResultadoCampoValorBancada == null && 
                        (this.selectedDigitacao == null || this.selectedDigitacao.id == null)){
                        this.$buefy.dialog.alert({
                            title: 'Filtros obrigatórios',
                            message: 'É preciso filtrar a bancada ou o setor.',
                            type: 'is-warning',
                            hasIcon: true
                        });
                        return;
                    }

                    if(this.digitarResultadoCampoValorBancada != null) {
                        url += "&bancadaNumero=" + this.digitarResultadoCampoValorBancada;
                    }

                    if(this.selectedDigitacao && this.selectedDigitacao.id) {
                        url += "&setorId=" + this.selectedDigitacao.id;
                    }
                }
                else if(this.digitarResultadoCampo == "guiaId"){
                    url += "&guiaId=" + this.digitarResultadoCampoValorBancada;
                }else if(this.digitarResultadoCampo == "codigoBarras") {
                    url += "&codigoBarras=" + this.digitarResultadoCampoValorBancada;
                }
                else if(this.digitarResultadoCampo == "exameApelido"){
                    if(this.selectedDigitacao && this.selectedDigitacao.id)
                        url += "&exameId=" + this.selectedDigitacao.id;
                }

                window.location = url;
            },
            onSubmitAssinarResultados(){
                let url = this.assinaturaTipo
                //Removo a parte do filtro por data, pois não precisa. Já que o usuário está inserindo o código da guia / id bancada e setor / id amostra / id exame.
                const filtros = this.periodoAssinaturaReq.split("&filtrarPor")[0];

                if(this.assinarResultadoCampo == "bancadaNumero"){

                    if(this.assinarCampoValorBancada == null && 
                        (this.selectedAssinatura == null || this.selectedAssinatura.id == null)){
                        this.$buefy.dialog.alert({
                            title: 'Filtros obrigatórios',
                            message: 'É preciso filtrar a bancada ou o setor.',
                            type: 'is-warning',
                            hasIcon: true
                        });
                        return;
                    }

                    if(this.assinarCampoValorBancada != null && this.selectedAssinatura && this.selectedAssinatura.id ) {
                        url += "?bancadaNumero=" + this.assinarCampoValorBancada + filtros;
                    }else if(this.assinarCampoValorBancada != null){
                        url += "?bancadaNumero=" + this.assinarCampoValorBancada + "&unidadeId=" + this.unidadeId + filtros;
                    }

                    if(this.selectedAssinatura && this.selectedAssinatura.id && this.assinarCampoValorBancada != null) {
                        url += "&setorId=" + this.selectedAssinatura.id + "&unidadeId=" + this.unidadeId + filtros;
                    }else if(this.selectedAssinatura && this.selectedAssinatura.id){
                        url += "?setorId=" + this.selectedAssinatura.id + "&unidadeId=" + this.unidadeId + filtros;
                    }
                }
                else if(this.assinarResultadoCampo == "guiaId"){
                    url += "?guiaId=" + this.assinarCampoValorBancada + "&unidadeId=" + this.unidadeId + filtros;
                }
                else if(this.assinarResultadoCampo == "codigoBarras"){
                    url += "?codigoBarras=" + this.assinarCampoValorBancada + "&unidadeId=" + this.unidadeId + filtros;
                }
                else if(this.assinarResultadoCampo == "exameApelido"){
                    if(this.selectedAssinatura && this.selectedAssinatura.id)
                        url += "?exameId=" + this.selectedAssinatura.id + "&unidadeId=" + this.unidadeId + filtros;
                }
                // por guia =  /Analise/AssinaturaGuia || em lista = /Guia/Assinatura - GuiA: 90003653

                window.location = url;
            },
            procurarSetorAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaSetorAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `nome=${name}`,
                ].join('&')
                this.$http.get(`/search/setor?${params}`)
                    .then(({ data }) => {
                        this.listaSetorAutocomplete = data
                    })
                    .catch((error) => {
                        this.listaSetorAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            procurarExameAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaExameAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `nome=${name}`,
                ].join('&')
                this.$http.get(`/search/exame?${params}`)
                    .then(({ data }) => {
                        this.listaExameAutocomplete = data
                    })
                    .catch((error) => {
                        this.listaExameAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            abrirLote() {
                this.$router.push({ name: 'abrir-lote', params: { id: this.lote } })
            },
            atualizaDadosAcompanhamento() {
                const qntDias = this.$moment(this.acompanhamentoDatas[1]).diff(this.$moment(this.acompanhamentoDatas[0]), 'days');
                
                if(qntDias > 60){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MENUANALISE.PERIDOMAXIMO'),
                        type: "is-danger",
                        queue: false,
                    });

                    this.examesBloqueados = this.amostrasIncidencia = this.estudosSemVinculo = 0;

                    return;
                }

                this.$http.get('api/Dashboard/QuantidadeExamesBloqueados?unidadeId=' + this.unidadeId + this.periodoAcompanhamentoReq)
                    .then(res => {
                        this.examesBloqueados = res.data
                    });

                this.$http.get('api/Dashboard/QuantidadeAmostrasIncidencia?unidadeId=' + this.unidadeId + this.periodoAcompanhamentoReq)
                    .then(res => {
                        this.amostrasIncidencia = res.data
                    });

                this.$http.get('api/Dashboard/QuantidadeEstudosSemVinculo?' + this.periodoAcompanhamentoReq)
                    .then(res => {
                        this.estudosSemVinculo = res.data
                    });
            },
        },
        mounted() {
            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarGuiasPendentes();
            }
        }
    }
</script>
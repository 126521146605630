<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field expanded class="search-area">
                    <b-input :placeholder="$t('EXAMESLISTA.PROCURARPOR') +colunaSelecionada"
                             v-model="procurar"
                             icon="magnify"
                             expanded
                             icon-clickable
                             @icon-click="loadAsyncData"
                             @input="loadAsyncData"></b-input>
                    <b-dropdown aria-role="list" v-model="colunaSelecionada" expanded>
                        <button class="button is-primary dropdown-opcoes" slot="trigger" slot-scope="{ active }">
                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                        </button>

                        <b-dropdown-item aria-role="listitem" value="Apelido">{{$t('EXAMESLISTA.APELIDO')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Nome">{{$t('EXAMESLISTA.NOME')}}</b-dropdown-item>
                    </b-dropdown>
                </b-field>
            </div>
        </div>
        <b-table :data="model.lista"
            :loading="loading"
            paginated
            striped
            hoverable
            backend-pagination
            :total="total"
            :current-page.sync="page"
            @page-change="onPageChange"
            :aria-next-label="$t('EXAMESLISTA.PROXPAGINA')"
            :aria-previous-label="$t('EXAMESLISTA.PAGANTERIOR')"
            :aria-page-label="$t('EXAMESLISTA.PAGINA')"
            :aria-current-label="$t('EXAMESLISTA.PAGATUAL')"
            backend-sorting
            sort-icon-size="is-small"
            style="overflow-x:auto;"
        >
            <template>
                <b-table-column field="Apelido" :label="$t('EXAMESLISTA.APELIDO')" >
                    <template v-slot="props">
                        {{ props.row.apelido }}
                    </template>
                </b-table-column>

                <b-table-column  field="Nome" :label="$t('EXAMESLISTA.NOME')" >
                    <template v-slot="props">
                        {{ props.row.nome }}
                    </template>
                </b-table-column>

                <b-table-column style="min-width: 300px;">
                    <template v-slot="props" > 
                        <div class="is-flex is-flex-wrap-nowrap is-align-items-center">
                            <div 
                                v-for="tabela in props.row.tabelas" :key="tabela.tabelaPreco.id" 
                                class="is-flex"
                                style="width:250px;">
                                <b-field 
                                    :label="tabela.tabelaPreco.nome" 
                                    :type="tabela.checked ? 'is-success' : ''"
                                    :icon-right="tabela.checked ? 'check' : ''"
                                >
                                    <div class="icon-alignment">
                                        <b-input                                                
                                            :value="tabela.valor | currency" 
                                            disabled
                                            style="min-width:234px"
                                        >
                                        </b-input>
                                        <b-tooltip v-if="tabela.bloqueado" :class="tabela.checked ? 'icon-position max-right-postion' : 'icon-position min-right-postion'" :label="tabela.motivoBloqueio ?? 'Motivo não informado'">
                                            <b-icon size="is-small" icon="block-helper" type="is-dark" />
                                        </b-tooltip>
                                    </div>
                                </b-field>
                            </div>                  
                        </div>                    
                    </template>
               </b-table-column> 
               
            </template>
        </b-table>
    </section>
</template>

<style lang="scss" scoped>
    .search-area {
        .dropdown {
            max-width: 2rem;
        }
    }

    .icon-alignment {
        display: flex;
        position: relative;
    }

    .icon-position {
        display: flex;
        position: absolute;
        top: 8px;
    }
    
    .min-right-postion {
        right: 8px;
    }

    .max-right-postion {
        right: 34px;
    } 
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                page: this.$route.query.page ?? 1,
                perPage: 20,
                selecionado: null,
                id: null,
                colunaDinamica: null,
                colunaSelecionada: 'Apelido'
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('EXAMESLISTA.MANUTENCAO'),
                    this.$t('EXAMESLISTA.TABELACUSTO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData: debounce(function () {
                const fieldName = this.colunaSelecionada;

                const params = [
                    `page=${this.page}`,
                    `fieldName=${fieldName}`,
                ]

                if(this.procurar != "")
                    params.push(`procurar=${this.procurar}`);

                this.loading = true
                this.$http.get(`/api/manutencao/comparacaoCustos?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }

                        this.model.lista.forEach(lista => {
                            let minValue = 0;

                            if(lista.tabelas?.filter(item => item.valor > 0 && !item.bloqueado).length > 0) {
                                minValue = lista.tabelas?.filter(item => item.valor > 0 && !item.bloqueado)[0].valor;
                            }

                            if(lista.tabelas.filter(item => item.valor > 0 && !item.bloqueado).length > 0) {
                                lista.tabelas.filter(item => item.valor > 0 && !item.bloqueado)[0].checked = true;
                            }

                            lista.tabelas.filter(item => item.valor === minValue && !item.bloqueado && item.valor > 0).forEach(tabela => {
                                tabela.checked = true;
                            });
                        });

                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },1000),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>